import {connect, useSelector} from "react-redux";
import {State} from "../../redux/reducers";
import React, {useEffect} from "react";
import {HOME_AUTO} from "../../navigation/CONSTANTS";
import {useHistory} from "react-router-dom";
import {Accordion, Button, Card, Col, Container, Row} from "react-bootstrap";
import logoTua from "../../assets/images/hand-thumbs-up-fill.svg";
import Util from "../../utils/commonUtil";
import {Fattore, Garanzia, QuotazioneReResponse, QuotazioneResponse} from "../../models/Quotazione/QuotazioneResponse";
import FattoreView from "./components/FattoreView";
import Copyright from "../../components/Copyright";
import QuotazioneUtils from "../../utils/QuotazioneUtils";

const RiepilogoContainer = ({quotazione}: Props) => {
    const history = useHistory();
    const homePage: string = useSelector((state: State) => state.global.configuration.homePage);

    const haFattoriVisibili = (fattori: Fattore[]): boolean => {
        return fattori && fattori.length > 0 && fattori.filter((f) => f.visibileInPreventivo && f.tipofattore)?.length > 0
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!quotazione) {
            history.push(HOME_AUTO);
        }
    }, []);

    return <div className="riepilogo-container base-container container">
        <Card className="q-card">
            <Card.Body>
                <div className="d-flex-column riepilogo-title">
                    <img className="icon" src={logoTua} alt=""/>
                    <div>Grazie per la fiducia!</div>
                    <div>Riceverai a breve una e-mail con il riepilogo della quotazione.</div>
                    <div>Ti aspettiamo in Agenzia</div>
                </div>

                <div className="riepilogo-header">
                    <Container>
                        <Row>
                            <Col xs={12} sm={6} className="d-flex-column">
                                <div className="riepilogo-header-label">PREMIO</div>
                                <div className="riepilogo-header-text">{Util.formattaImporto(quotazione?.importo)}</div>
                            </Col>
                            <Col xs={12} sm={6} className="d-flex-column">
                                <div className="riepilogo-header-label">IL TUO CODICE DI QUOTAZIONE</div>
                                <div className="riepilogo-header-text">{quotazione?.numPreventivo}</div>
                            </Col>
                        </Row>
                    </Container>

                </div>

                <div className="riepilogo-body">
                    <div>DETTAGLIO QUOTAZIONE</div>
                    <Accordion alwaysOpen={false} flush className="q-accordion">
                        {QuotazioneUtils.getGaranzie(quotazione).map((garanzia: Garanzia) => (
                            <>{garanzia.selezionata && <Accordion.Item eventKey={garanzia.codice}>
								<Accordion.Header className={haFattoriVisibili(garanzia.fattori) ? '' : 'no-body'}>
                                    {garanzia.garanzie?.length > 0 ? garanzia.garanzie.find(g => {
                                        return g.codice === garanzia.codiceGaranziaSelezionata
                                    })?.descrizione : garanzia.descrizione}
								</Accordion.Header>
                                {haFattoriVisibili(garanzia.fattori) && garanzia.fattori?.map((fattore: Fattore) => (
                                    <>{fattore.tipofattore && <Accordion.Body>
										<Container className="fattori-view-container"><FattoreView fattore={fattore}/></Container>
									</Accordion.Body>}</>
                                ))}
							</Accordion.Item>}
                            </>
                        ))}
                    </Accordion>
                </div>

                <div className="riepilogo-footer d-flex-column">
                    <Button className="q-button q-button-primary" href={homePage}>TORNA ALLA HOME</Button>
                    <Copyright/>
                </div>
            </Card.Body>
        </Card>
    </div>
}

type Props = {
    quotazione: QuotazioneResponse | QuotazioneReResponse | null;
};

const mapStateToProps = (state: State) => ({
    quotazione: state.quotazione.quotazioneResult || state.quotazione.quotazioneReResult
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RiepilogoContainer);
