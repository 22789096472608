import {QuotazioneReRequest} from "../models/Request/QuotazioneReRequest";

export const ERROR_NO_MARCA_MODELLO = '96';

export const OFFERTA_ACTIVE_BENESSERE: QuotazioneReRequest = {
    "prodotto": "0006",
    "origine": "12",
    "privacy": 1,
    "beniIniziali": [
        {
            "codice": "000052",
            "descrizione1": "ASSICURATO 1",
            "tipoBene": "SALUTE",
            "fattori": [
                {
                    "codice": "_5397",
                    "descrizione": "Tipo Formula",
                    "nonModificabile": true,
                    "obbligatorio": true,
                    "presenzahelp": false,
                    "tipofattore": "S",
                    "valori": [
                        {
                            "codice": "2",
                            "descrizione": "Formula Persona"
                        }
                    ],
                    "codiceValoreSelezionato": "2",
                    "visibileInPreventivo": false
                },
                {
                    "codice": "Q_PROVINCIA",
                    obbligatorio: true,
                    "descrizione": "Provincia",
                    "tipofattore": "TESTO",
                    "nonModificabile": true,
                    "visibileInPreventivo": false
                },
                {
                    "codice": "Q_COMUNE",
                    obbligatorio: true,
                    "descrizione": "Comune",
                    "tipofattore": "TESTO",
                    "nonModificabile": true,
                    "visibileInPreventivo": false
                },
                {
                    "codice": "Q_CAP",
                    obbligatorio: true,
                    "descrizione": "Cap",
                    "tipofattore": "TESTO",
                    "nonModificabile": true,
                    "visibileInPreventivo": false
                },
                {
                    "codice": "Q_INDIRIZZO",
                    obbligatorio: true,
                    "descrizione": "Indirizzo",
                    "tipofattore": "TESTO",
                    "nonModificabile": true,
                    "visibileInPreventivo": false
                },
                {
                    "codice": "Q_CIVICO",
                    obbligatorio: true,
                    "descrizione": "Numero civico",
                    "tipofattore": "TESTO",
                    "nonModificabile": true,
                    "visibileInPreventivo": false
                },
                {
                    "codice": "Q_ISTAT",
                    obbligatorio: true,
                    "descrizione": "Istat",
                    "tipofattore": "TESTO",
                    "visibileInPreventivo": false,
                    "nonModificabile": true,
                }
            ],
            "clausole": [
                {
                    "codice": "CHECKU",
                    "descrizione": "Check-up Base",
                    "modificabile": true,
                    "presenzahelp": false,
                    "selezionata": true,
                    "visibileInPreventivo": true
                },
                {
                    "codice": "CARDSA",
                    "descrizione": "Card Sanitaria",
                    "modificabile": false,
                    "presenzahelp": false,
                    "selezionata": true,
                    "visibileInPreventivo": true
                },
                {
                    "codice": "PREVSD",
                    "descrizione": "Servizi di tele-videoconsulto",
                    "modificabile": false,
                    "presenzahelp": false,
                    "selezionata": true,
                    "visibileInPreventivo": true
                },
                {
                    "codice": "SERDPB",
                    "descrizione": "Servizi digitali Portale Benessere",
                    "modificabile": false,
                    "presenzahelp": false,
                    "selezionata": true,
                    "visibileInPreventivo": true
                },
                {
                    "codice": "CLP100",
                    "descrizione": "L'assicurando dichiara, ad ogni effetto di legge, che le informazioni e le risposte date a tutte le domande del presente questionario sono veritiere e che non ha taciuto, omesso, od alterato alcuna circostanza in rapporto al questionario stesso.",
                    "modificabile": false,
                    "presenzahelp": false,
                    "selezionata": true,
                    "visibileInPreventivo": true
                }
            ]
        }
    ],
    "clausolePolizza": [],
    "fattoriPolizza": [
        {
            "codice": "PcversioneBindicizzazione",
            "descrizione": "Indicizzazione",
            "nonModificabile": true,
            "obbligatorio": true,
            "presenzahelp": false,
            "tipofattore": "C",
            "valore": 1,
            "visibileInPreventivo": false
        },
        {
            "codice": "PcversioneIdtipoindicizz",
            "descrizione": "Tipo Indicizzazione",
            "tipofattore": "S",
            "codiceValoreSelezionato": "32",
            "valori": [
                {
                    "descrizione": "Servizi sanitari spese per la salute",
                    "codice": "32"
                }
            ],
            "nonModificabile": true,
            "obbligatorio": true,
            "visibileInPreventivo": false
        },
        {
            "codice": "_5516",
            "descrizione": "Forma di Copertura",
            "tipofattore": "S",
            "codiceValoreSelezionato": "1",
            "valori": [
                {
                    "descrizione": "Standard",
                    "codice": "1"
                },
                {
                    "descrizione": "Clausola Non-Stop",
                    "codice": "2"
                }
            ],
            "valoriVisibili": [
                {
                    "descrizione": "Standard",
                    "codice": "1"
                },
                {
                    "descrizione": "Clausola Non-Stop",
                    "codice": "2"
                }
            ],
            "nonModificabile": false,
            "obbligatorio": true,
            "visibileInPreventivo": false
        }
    ],
}

export const OFFERTA_ACTIVE_CASA: QuotazioneReRequest = {
    "origine": "12",
    "prodotto": "0005",
    "privacy": 1,
    "beniIniziali": [
        {
            "codice": "000045",
            "descrizione1": "ABITAZIONE",
            "tipoBene": "CASA",
            "fattori": [{
                "codice": "_1037",
                "descrizione": "Tipologia abitazione",
                "tipofattore": "S",
                "codiceValoreSelezionato": "1",
                "valori": [
                    {
                    "codice": "1",
                    "descrizione": "Appartamento"
                },
                    {
                        "codice": "2",
                        "descrizione": "Villa a schiera/Villa plurifamiliare"
                    },
                    {
                        "codice": "3",
                        "descrizione": "Villa unifamiliare"
                    }
                ],
                "nonModificabile": false,
                "obbligatorio": true,
                "visibileInPreventivo": true
            },
                {
                    "codice": "_2067",
                    "descrizione": "Relazione con l'assicurato",
                    "tipofattore": "S",
                    "codiceValoreSelezionato": "1",
                    "valori": [
                        {
                        "codice": "1",
                        "descrizione": "Proprietario"
                    },
                        {
                            "codice": "2",
                            "descrizione": "Affittuario"
                        }
                    ],
                    "nonModificabile": false,
                    "obbligatorio": true,
                    "visibileInPreventivo": true
                },
                {
                    "codice": "_4095",
                    "descrizione": "Totale Mq",
                    "tipofattore": "I",
                    "valore": 101,
                    "nonModificabile": false,
                    "obbligatorio": true,
                    "visibileInPreventivo": true
                },
                {
                    "codice": "Q_PROVINCIA",
                    "descrizione": "Provincia",
                    "tipofattore": "TESTO",
                    "nonModificabile": false,
                    "obbligatorio": true,
                    "visibileInPreventivo": true
                },
                {
                    "codice": "Q_COMUNE",
                    "descrizione": "Comune",
                    "tipofattore": "TESTO",
                    "nonModificabile": false,
                    "obbligatorio": true,
                    "visibileInPreventivo": true
                },
                {
                    "codice": "Q_CAP",
                    "descrizione": "Cap",
                    "tipofattore": "TESTO",
                    "nonModificabile": false,
                    "obbligatorio": true,
                    "visibileInPreventivo": true
                },
                {
                    "codice": "Q_INDIRIZZO",
                    "descrizione": "Indirizzo",
                    "tipofattore": "TESTO",
                    "nonModificabile": false,
                    "obbligatorio": true,
                    "visibileInPreventivo": true
                },
                {
                    "codice": "Q_CIVICO",
                    "descrizione": "N civico",
                    "tipofattore": "TESTO",
                    "nonModificabile": false,
                    "obbligatorio": true,
                    "visibileInPreventivo": true
                },
            ]
        },
        {
            "codice": "F00045",
            "descrizione1": "PERSONA",
            "tipoBene": "RC",
            "fattori": [],
            "dataNascita": new Date()
        }
    ]
}
