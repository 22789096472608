import React from "react";
import {Button} from "react-bootstrap";
import Util from "../../../utils/commonUtil";
import {SALVATAGGIO} from "../../../navigation/CONSTANTS";
import {useHistory} from "react-router-dom";


const QuotazioneFooterContainer = ({premio, daRicalcolare, ricalcola, bloccaProsegui}: Props) => {
    const history = useHistory();
    const prosegui = () => {
        history.push(SALVATAGGIO);
    }


    return <div className="footer-container d-flex">
        <div className="container d-flex align-items-center">
            <div className="amount-container">
                <div className="text q-txt-big q-txt-black q-bolder-600">PREMIO ANNUO</div>
                <div className="value">{Util.formattaImporto(premio)}</div>
            </div>
            {daRicalcolare && <div className="action-container"><Button className="q-button q-button-primary" onClick={() => {ricalcola()}}>RICALCOLA</Button></div>}
            {!daRicalcolare && <div className="action-container"><Button className="q-button q-button-primary" disabled={bloccaProsegui} onClick={prosegui}>PROSEGUI</Button></div>}
        </div>
    </div>
}

type Props = {
    premio: number | undefined,
    daRicalcolare: boolean,
    ricalcola: () => void,
    bloccaProsegui : boolean,
}

export default QuotazioneFooterContainer;
