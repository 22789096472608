import axios, {AxiosInstance} from 'axios';
import CryptoJS from 'crypto-js';
import {DatiPrime, QuotazioneReResponse, QuotazioneResponse} from '../models/Quotazione/QuotazioneResponse';
import {Offerta, OfferteResponse} from '../models/OfferteResponse';
import {InfoHelpResponse} from '../models/Response/InfoHelpResponse';
import {ensure} from '../utils/commonUtil';
import {ElencoMarcheResponse} from '../models/Veicolo/Marche';
import {ElencoModelliResponse} from '../models/Veicolo/Modelli';
import {AllestimentoRequest, AllestimentoResponse} from '../models/Veicolo/Allestimento';
import {SalvaQuotazioneRequest, SalvaReRequest} from '../models/Request/SalvaQuotazioneRequest';
import store from '../redux/store';
import {disableLoader, enableLoader} from '../redux/actions/globalActions';
import InputQuotazioneVeicolo from '../models/Input/InputQuotazioneVeicolo';
import {ERROR_NO_MARCA_MODELLO, OFFERTA_ACTIVE_BENESSERE, OFFERTA_ACTIVE_CASA} from './CONSTANTS';
import InputQuotazioneCasa from "../models/Input/InputQuotazioneCasa";
import InputQuotazioneSalute from "../models/Input/InputQuotazioneSalute";
import {QuotazioneReRequest} from "../models/Request/QuotazioneReRequest";
import {popolaLocalitaBeneIniziale} from "../models/Quotazione/BeniIniziali";
import _ from "lodash";
import InputQuotazioneInfortuni from "../models/Input/InputQuotazioneInfortuni";
import {Configuration} from "../models/Configuration";

const BE_URL = '/Quotti/rest';

class ApiService {
    http: AxiosInstance;

    constructor() {
        this.http = axios.create({
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.http.interceptors.request.use(
            (config) => {
                store.dispatch(enableLoader());
                return config;
            },
            (error) => {
                store.dispatch(disableLoader());
                return Promise.reject(error);
            },
        );

        this.http.interceptors.response.use(
            (response) => {
                store.dispatch(disableLoader());
                return response;
            },
            (error) => {
                store.dispatch(disableLoader());
                return Promise.reject(error);
            },
        );
    }

    decrypt(data: string): any {
        const rawData = CryptoJS.enc.Base64.parse(data);
        const key = CryptoJS.enc.Utf8.parse('weJiSEvZ5yApoLLo');
        const iv = CryptoJS.enc.Utf8.parse('ssdkF$HUe2ApoLLo');
        const cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: rawData});
        const plaintextData = CryptoJS.AES.decrypt(cipherParams, key, {iv: iv});
        return JSON.parse(plaintextData.toString(CryptoJS.enc.Utf8));
    }

    encrypt(data: any): string {
        const key = CryptoJS.enc.Utf8.parse('weJiSEvZ5yApoLLo');
        const iv = CryptoJS.enc.Utf8.parse('ssdkF$HUe2ApoLLo');
        const plaintextData = CryptoJS.AES.encrypt(JSON.stringify(data), key, {iv: iv});
        return CryptoJS.enc.Base64.stringify(plaintextData.ciphertext);
    }

    async getInfoHelp() {
        try {
            const res = await this.http.get(`${BE_URL}/quotazioneb2c/infoB2C`);
            return this.decrypt(res.data) as InfoHelpResponse;
        } catch (e) {
            return null;
        }
    }

    async getInfoHelpRe(codiceProdotto: string) {
        try {
            const params = {codiceProdotto};
            const res = await this.http.get(`${BE_URL}/quotazioneReb2c/infoB2C`, {params});
            return this.decrypt(res.data) as InfoHelpResponse;
        } catch (e) {
            return null;
        }
    }

    async getElencoAttivita(idProdotto: number) {
        try {
            const params = {idProdotto};
            const res = await this.http.get(`${BE_URL}/factory/elencoAttivita`, {params});
            return (res.data.listaAttivita);
        } catch (e) {
            return null;
        }
    }

    async getOffertaAuto() {
        try {
            const res = await this.http.post(`${BE_URL}/quotazioneb2c/getOfferteB2C`, '0');
            const response = this.decrypt(res.data) as OfferteResponse;
            return response.elencoOffertaB2C[0]?.offerta;
        } catch (e) {
            throw e;
        }
    }

    async getMarcheQR() {
        const res = await this.http.get<ElencoMarcheResponse>(`${BE_URL}/factory/elencoMarcheQr`);
        return res.data.elencoMarche;
    }

    async getModelliQR(marca: string, tipoVeicolo?: string) {
        const params = {marca, tipoVeicolo};
        const res = await this.http.get<ElencoModelliResponse>(`${BE_URL}/factory/elencoModelliQr`, {params});
        return res.data.elencoModelli;
    }

    async getAllestimentiQR(data: AllestimentoRequest) {
        const res = await this.http.post(`${BE_URL}/quotazioneb2c/getAllestimentiQr`, this.encrypt(data));
        const decrypted: AllestimentoResponse = this.decrypt(res.data);
        return decrypted.elencoAllestimenti;
    }

    async getMarcheDR() {
        const res = await this.http.get<ElencoMarcheResponse>(`${BE_URL}/factory/elencoMarcheDr`);
        return res.data.elencoMarche;
    }

    async getModelliDR(marca: string, tipoVeicolo: string) {
        const params = {marca, tipoVeicolo};
        const res = await this.http.get<ElencoModelliResponse>(`${BE_URL}/factory/elencoModelliDr`, {params});
        return res.data.elencoModelli;
    }

    async getAllestimentiDR(data: AllestimentoRequest) {
        const res = await this.http.post(`${BE_URL}/quotazioneb2c/getAllestimentiMotocicloMM`, this.encrypt(data));
        const decrypted: AllestimentoResponse = this.decrypt(res.data);
        return decrypted.elencoAllestimenti;
    }

    getCookie(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(`${BE_URL}/quotazioneb2c/getCookie`)
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        })
    }

    salva(request: SalvaQuotazioneRequest): Promise<QuotazioneResponse> {
        return new Promise((resolve, reject) => {
            this.http
                .put<string>(`${BE_URL}/quotazioneb2c/salvaB2c`, this.encrypt(request))
                .then((res) => {
                    const quotazione = this.decrypt(res.data) as QuotazioneResponse;

                    if (quotazione.errore?.listaErrori?.length > 0) {
                        reject(this.fixMessage(quotazione.errore.listaErrori[0]));
                    }

                    resolve(quotazione);
                })
                .catch((err) => reject(err));
        });
    }

    ricalcola(request: QuotazioneResponse): Promise<QuotazioneResponse> {
        return new Promise((resolve, reject) => {
            this.http
                .post<string>(`${BE_URL}/quotazioneb2c/ricalcolaQuotazioneB2c`, this.encrypt(request))
                .then((res) => {
                    const quotazione = this.decrypt(res.data) as QuotazioneResponse;
                    if (quotazione.errore?.listaErrori?.length > 0) {
                        reject(this.fixMessage(quotazione.errore.listaErrori[0]));
                    }
                    resolve(quotazione);
                })
                .catch((err) => {
                    reject(err)
                });
        });
    }

    private fixMessage(message: string) {
        return message.replaceAll(
            /style='text-decoration: underline'/g,
            "style='text-decoration: underline; color: white'",
        );
    }

    calcola = (input: InputQuotazioneVeicolo, offertaAuto: Offerta): Promise<QuotazioneResponse> => {
        const request = {} as QuotazioneResponse;
        request.email = input.email;
        request.targa = input.targa;
        request.dataNascita = input.dataNascitaProprietario;
        request.datiPrime = {} as DatiPrime;
        request.datiPrime.offerta = ensure(offertaAuto);
        request.marca = input.marca?.codice;
        request.modello = input.modello?.codice;
        request.allestimento = input.allestimento?.codice;

        return new Promise((resolve, reject) => {
            this.http
                .post<string>(`${BE_URL}/quotazioneb2c/quotazioneB2c`, this.encrypt(request))
                .then((res) => {
                    const quotazione = this.decrypt(res.data) as QuotazioneResponse;
                    if (quotazione.errore?.listaErrori?.length > 0 && quotazione.errore?.codiceEsito !== ERROR_NO_MARCA_MODELLO) {
                        reject(this.fixMessage(quotazione.errore.listaErrori[0]));
                    }

                    resolve(quotazione);
                })
                .catch((err) => reject(err));
        });
    };

    calcolaCasa = (input: InputQuotazioneCasa): Promise<QuotazioneReResponse> => {
        const request: QuotazioneReRequest = _.cloneDeep(OFFERTA_ACTIVE_CASA);
        request.beniIniziali[0].fattori![0].codiceValoreSelezionato = input.tipologiaAbitazione.codice;
        request.beniIniziali[0].fattori![1].codiceValoreSelezionato = input.tipologiaProprietario.codice;
        request.beniIniziali[0].fattori![2].valore = input.mqCalpestabili;
        popolaLocalitaBeneIniziale(request.beniIniziali[0], input);
        request.beniIniziali[1].dataNascita = input.dataNascitaProprietario;

        return new Promise((resolve, reject) => {
            this.http
                .post<string>(`${BE_URL}/quotazioneReb2c/quotazioneB2C`, this.encrypt(request))
                .then((res) => {
                    const quotazione = this.decrypt(res.data) as QuotazioneReResponse;
                    if (quotazione.errore?.listaErrori?.length > 0) {
                        reject(this.fixMessage(quotazione.errore.listaErrori[0]));
                    }
                    resolve(quotazione);
                })
                .catch((err) => reject(err));
        })
    }

    ricalcolaRe(request: QuotazioneReResponse): Promise<QuotazioneReResponse> {
        return new Promise((resolve, reject) => {
            this.http
                .post<string>(`${BE_URL}/quotazioneReb2c/ricalcolaB2C`, this.encrypt(request))
                .then((res) => {
                    const quotazione = this.decrypt(res.data) as QuotazioneReResponse;
                    if (quotazione.errore?.listaErrori?.length > 0) {
                        reject(this.fixMessage(quotazione.errore.listaErrori[0]));
                    }
                    resolve(quotazione);
                })
                .catch((err) => {
                    reject(err)
                });
        });
    }

    calcolaSalute = (input: InputQuotazioneSalute): Promise<QuotazioneReResponse> => {
        let request: QuotazioneReRequest = _.cloneDeep(OFFERTA_ACTIVE_BENESSERE);
        request.beniIniziali[0].dataNascita = input.dataNascita;
        request.beniIniziali[0].statoSalute = {altezza: input.altezza, peso: input.peso};
        popolaLocalitaBeneIniziale(request.beniIniziali[0], input);
        request.beniIniziali[0].attivita = input.attivita;
        request.email = input.email;

        return new Promise((resolve, reject) => {
            this.http
                .post<string>(`${BE_URL}/quotazioneReb2c/quotazioneB2C`, this.encrypt(request))
                .then((res) => {
                    const quotazione = this.decrypt(res.data) as QuotazioneReResponse;
                    if (quotazione.errore?.listaErrori?.length > 0) {
                        reject(this.fixMessage(quotazione.errore.listaErrori[0]));
                    }
                    resolve(quotazione);
                })
                .catch((err) => reject(err));
        })
    }

    calcolaInfortuni = (input: InputQuotazioneInfortuni): Promise<QuotazioneReResponse> => {
        let request: QuotazioneReRequest = _.cloneDeep(OFFERTA_ACTIVE_BENESSERE);
        request.prodotto = "T0004";
        request.beniIniziali[0].codice = '000101';
        request.beniIniziali[0].dataNascita = input.dataNascita;
        popolaLocalitaBeneIniziale(request.beniIniziali[0], input);
        request.beniIniziali[0].attivita = input.attivita;
        // request.email = input.email;

        return new Promise((resolve, reject) => {
            this.http
                .post<string>(`${BE_URL}/quotazioneReb2c/quotazioneB2C`, this.encrypt(request))
                .then((res) => {
                    const quotazione = this.decrypt(res.data) as QuotazioneReResponse;
                    if (quotazione.errore?.listaErrori?.length > 0) {
                        reject(this.fixMessage(quotazione.errore.listaErrori[0]));
                    }
                    resolve(quotazione);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    salvaRe(request: SalvaReRequest): Promise<QuotazioneReResponse> {
        return new Promise((resolve, reject) => {
            this.http
                .put<string>(`${BE_URL}/quotazioneReb2c/salvaRe`, this.encrypt(request))
                .then((res) => {
                    const quotazione = this.decrypt(res.data) as QuotazioneReResponse;

                    if (quotazione.errore?.listaErrori?.length > 0) {
                        reject(this.fixMessage(quotazione.errore.listaErrori[0]));
                    }

                    resolve(quotazione);
                })
                .catch((err) => reject(err));
        });
    }

    getConfig(): Promise<Configuration> {
        return new Promise((resolve, reject) => {
            this.http.get(`${BE_URL}/quotazioneb2c/config`)
                .then((res) => {
                    resolve(this.decrypt(res.data) as Configuration);
                })
                .catch((err) => reject(err));
        })
    }
}

export const apiService = new ApiService();
