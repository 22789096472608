import {useDispatch, useSelector} from "react-redux";
import {State} from "../redux/reducers";
import {Alert} from "react-bootstrap";
import {closeAlert} from "../redux/actions/globalActions";
import {AlertMessage, AlertMessages} from "../models/AlertMessages";

const AlertContainer = () => {
    const alert: AlertMessages = useSelector((state: State) => state.global.alert);
    const dispatch = useDispatch();

    const dismissAlert = ((element : AlertMessage) => {
        element.hide = true;
        dispatch(closeAlert({messages: alert}));
    })

    return <div className="q-alert-container">
        {alert?.info && alert?.info.map((e, index) => !e.hide && <Alert variant='info' key={'info'.concat(index.toString())} onClose={() => dismissAlert(e)}
                                                             dismissible><span dangerouslySetInnerHTML={{__html: e.text}}/></Alert>)}
        {alert?.warning && alert?.warning.map((e, index) => !e.hide && <Alert variant='warning' key={'warning'.concat(index.toString())} onClose={() => dismissAlert(e)}
                                                                   dismissible><span dangerouslySetInnerHTML={{__html: e.text}}/></Alert>)}
        {alert?.danger && alert?.danger.map((e, index) => !e.hide && <Alert variant='danger' key={'danger'.concat(index.toString())} onClose={() => dismissAlert(e)}
                                                                 dismissible><span dangerouslySetInnerHTML={{__html: e.text}}/></Alert>)}
    </div>;
}

export default AlertContainer;
