import {Veicolo} from "../../../../models/Quotazione/QuotazioneResponse";
import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import Util from "../../../../utils/commonUtil";

const QuotazioneHeaderVeicoloDettaglio = ({targa, veicolo, icon}: Props) => {
    return <>
        <div className="d-flex modal-veicolo-header tile">
            <img className={`logo ${veicolo.tipoVeicolo === 'A' ? '' : 'logo-bike'}`} src={icon} alt="" />
            <div>
                <div className="q-txt-medium q-bold">DATI VETTURA</div>
                <div>{targa}</div>
                <div>{veicolo.marca}{veicolo.modello ? " - " + veicolo.modello : ""}</div>
            </div>
        </div>

        <Container className="modal-veicolo-body">
            <Row>
                {veicolo.allestimento && <Col xs="12">
				    <div className="q-bold">Allestimento</div>
				    <div>{veicolo.allestimento}</div>
			    </Col>}
                {veicolo.segmentoCommerciale && <Col xs="12">
				    <div className="q-bold">Segmento Commerciale</div>
				    <div>{veicolo.segmentoCommerciale}</div>
			    </Col>}
                {veicolo.meseImmatricolazione && veicolo.annoImmatricolazione && <Col xs="12" sm="6" lg="4">
				    <div className="q-bold">Immatricolazione</div>
				    <div>{veicolo.meseImmatricolazione + " / " + veicolo.annoImmatricolazione}</div>
			    </Col>}
                {veicolo.alimentazione && <Col xs="12" sm="6" lg="4">
				    <div className="q-bold">Alimentazione</div>
				    <div>{veicolo.alimentazione}</div>
			    </Col>}
                {veicolo.valoreAssicurato != null && veicolo.valoreAssicurato > 0 && <Col xs="12" sm="6" lg="4">
				    <div className="q-bold">Valore assicurato</div>
				    <div>{Util.formattaImporto(veicolo.valoreAssicurato)}</div>
			    </Col>}
                {veicolo.tipoGuidaSelezionato?.descrizione && <Col xs="12" sm="6" lg="4">
				    <div className="q-bold">Tipo guida</div>
				    <div>{veicolo.tipoGuidaSelezionato.descrizione}</div>
			    </Col>}
                {veicolo.cilindrata && <Col xs="12" sm="6" lg="4">
				    <div className="q-bold">Cilindrata</div>
				    <div>{veicolo.cilindrata}</div>
			    </Col>}
                {veicolo.kw && veicolo.cavalliFiscali && <Col xs="12" sm="6" lg="4">
				    <div className="q-bold">KW / Cavalli fiscali</div>
				    <div>{veicolo.kw + " / " + veicolo.cavalliFiscali}</div>
			    </Col>}
            </Row>
        </Container>
    </>
}

type Props = {
    targa: string,
    veicolo: Veicolo,
    icon: string
}
export default QuotazioneHeaderVeicoloDettaglio;
