import {Redirect, Route, HashRouter as Router} from 'react-router-dom';
import {
    EXTERNAL_HOME_AUTO,
    EXTERNAL_HOME_CASA,
    EXTERNAL_HOME_MOTO,
    EXTERNAL_HOME_SALUTE,
    EXTERNAL_HOME_INFORTUNI,
    HOME_AUTO,
    HOME_CASA, HOME_INFORTUNI,
    HOME_MOTO,
    HOME_SALUTE,
    QUOTAZIONE_AUTO,
    QUOTAZIONE_CASA, QUOTAZIONE_INFORTUNI,
    QUOTAZIONE_MOTO,
    QUOTAZIONE_SALUTE,
    RIEPILOGO,
    ROOT,
    SALVATAGGIO
} from './CONSTANTS';
import HomeAutoContainer from '../pages/Home/HomeAutoContainer';
import HomeMotoContainer from '../pages/Home/HomeMotoContainer';
import HomeCasaContainer from '../pages/Home/HomeCasaContainer';
import HomeSaluteContainer from '../pages/Home/HomeSaluteContainer';
import HomeInfortuniContainer from '../pages/Home/HomeInfortuniContainer';
import QuotazioneVeicoloContainer from '../pages/Quotazione/QuotazioneVeicoloContainer';
import QuotazioneCasaContainer from '../pages/Quotazione/QuotazioneCasaContainer';
import SalvataggioContainer from "../pages/Salvataggio/SalvataggioContainer";
import RiepilogoContainer from "../pages/Riepilogo/RiepilogoContainer";
import QuotazioneSaluteContainer from "../pages/Quotazione/QuotazioneSaluteContainer";
import QuotazioneInfortuniContainer from "../pages/Quotazione/QuotazioneInfortuniContainer";

export const RouterConfig = () => {
    return (
        <Router>
            <Route exact path={[HOME_AUTO, EXTERNAL_HOME_AUTO]} component={HomeAutoContainer}/>
            <Route exact path={[HOME_MOTO, EXTERNAL_HOME_MOTO]} component={HomeMotoContainer}/>
            <Route exact path={[QUOTAZIONE_AUTO, QUOTAZIONE_MOTO]} component={QuotazioneVeicoloContainer}/>
            <Route exact path={[HOME_CASA, EXTERNAL_HOME_CASA]} component={HomeCasaContainer}/>
            <Route exact path={QUOTAZIONE_CASA} component={QuotazioneCasaContainer}/>
            <Route exact path={[HOME_SALUTE, EXTERNAL_HOME_SALUTE]} component={HomeSaluteContainer}/>
            <Route exact path={QUOTAZIONE_SALUTE} component={QuotazioneSaluteContainer}/>
            <Route exact path={[HOME_INFORTUNI, EXTERNAL_HOME_INFORTUNI]} component={HomeInfortuniContainer}/>
            <Route exact path={QUOTAZIONE_INFORTUNI} component={QuotazioneInfortuniContainer}/>
            <Route exact path={SALVATAGGIO} component={SalvataggioContainer}/>
            <Route exact path={RIEPILOGO} component={RiepilogoContainer}/>
            <Route exact path={ROOT}>
                <Redirect to={HOME_AUTO}/>
            </Route>
        </Router>
    );
};
