import React from "react";
import iconCasa from "../../../../assets/images/house-fill.svg";

const QuotazioneHeaderCasa = ({tipologiaAbitazione, cap, comune}: Props) => {

    return(
        <div className="no-mobile-view">
            <div className="d-flex tile">
                <img className={`logo`} src={iconCasa} alt="" />
                <div className="border border-light rounded">
                    <div>{tipologiaAbitazione}</div>
                    <div>{cap}, {comune}</div>
                </div>
            </div>
        </div>
    )
}

type Props =
    {
        tipologiaAbitazione: string;
        cap: string;
        comune : string
    }

export default QuotazioneHeaderCasa;

