import {all, put, take, takeEvery} from 'redux-saga/effects';
import {
    bloccaQuotazione,
    disableLoader,
    enableLoader,
    GlobalActionTypes,
    loadConfig,
    loadConfigSuccess,
    loadElencoAttivita,
    loadElencoAttivitaSuccess,
    loadInfoHelp,
    loadInfoHelpSuccess,
    loadInfoReHelp,
    loadMarcheDr,
    loadOffertaAuto,
    loadOffertaAutoSuccess,
    ready,
    showAlert
} from '../../actions/globalActions';
import {apiService} from '../../../services/ApiService';
import {Offerta} from '../../../models/OfferteResponse';
import {InfoHelpResponse} from '../../../models/Response/InfoHelpResponse';
import {Attivita} from "../../../models/Quotazione/Attivita";
import {InfoHelp} from "../../../models/InfoHelp";
import {Configuration} from "../../../models/Configuration";

export function* loadCookieAsync(action: { type: string; quotationType: string }) {
    /**
     * Questo blocco carica i dati che serviranno per eseguire le diverse quotazioni.
     * È stato inserito dentro un unico blocco di try\catch e al primo servizio, necessario alla quotazione, che spacchi viene attivato il parametro bloccaQuotazione dentro lo store;
     * questo parametro rimarrà attivo fino al refresh della pagina.
     * Se vengono inseriti servizi di inizializzazione necessari bisogna far si che la loro implementazione lanci un'eccezione, come per il metodo getCookie;
     * mentre se non sono necessari, come per il metodo loadInfoHelp, basterà ritornare null dentro il blocco di catch*/
    try {
        yield put(enableLoader());
        yield apiService.getCookie();
        yield put(loadConfig());
        switch (action.quotationType) {
            case 'auto':
                yield all([put(loadOffertaAuto()), put(loadInfoHelp())]);
                yield take(GlobalActionTypes.LOAD_OFFERTA_AUTO_SUCCESS)
                break;
            case 'moto':
                yield all([put(loadOffertaAuto()), put(loadMarcheDr()), put(loadInfoHelp())]);
                yield all([take(GlobalActionTypes.LOAD_OFFERTA_AUTO_SUCCESS), take(GlobalActionTypes.LOAD_MARCHE_DR_SUCCESS)])
                break;
            case 'casa':
                yield put(loadInfoReHelp('0005'));
                break;
            case 'salute':
                yield put(loadInfoReHelp('T0006'));
                yield put(loadElencoAttivita(370));
                yield take(GlobalActionTypes.LOAD_ELENCO_ATTIVITA_SUCCESS)
                break;
            case 'infortuni':
                yield put(loadInfoReHelp('T0004'));
                yield put(loadElencoAttivita(306));
                yield take(GlobalActionTypes.LOAD_ELENCO_ATTIVITA_SUCCESS)
                break;
        }
        yield takeEvery(GlobalActionTypes.LOAD_CONFIG_SUCCESS, loadConfig)
    } catch (e) {
        yield put(bloccaQuotazione());
        yield put(showAlert({danger: [{text: "Impossibile eseguire una quotazione. Si prega di riprovare più tardi"}]}))
    } finally {
        yield put(disableLoader());
        yield put(ready());
    }
}

export function* loadOffertaAsync() {
    const offerta: Offerta = yield apiService.getOffertaAuto();
    yield put(loadOffertaAutoSuccess({offerta}));
}

export function* loadInfoHelpAsync() {
    const infoHelpResponse: InfoHelpResponse = yield apiService.getInfoHelp();
    let infoHelpMap: Map<string, InfoHelp> = new Map();
    infoHelpResponse.elencoDescrizioniInfoHelp.forEach(help => {
        if (help.rischio != null) {
            infoHelpMap.set(help.rischio, {titolo: help.titolo, testo: help.descrizione})
        }
    })
    yield put(loadInfoHelpSuccess({infoHelpMap}));
}

export function* loadInfoHelpReAsync(action: { type: string; codiceProdotto: string }) {
    const infoHelpResponse: InfoHelpResponse = yield apiService.getInfoHelpRe(action.codiceProdotto);
    let infoHelpMap: Map<string, InfoHelp> = new Map();
    infoHelpResponse.elencoDescrizioniInfoHelp.forEach(help => {
        if (help.rischio != null) {
            infoHelpMap.set(help.rischio, {titolo: help.titolo, testo: help.descrizione})
        }
    })
    yield put(loadInfoHelpSuccess({infoHelpMap}));
}

export function* loadElencoAttivitaAsync(action: { type: string; idProdotto: number }) {
    yield put(enableLoader());
    const response: Attivita[] = yield apiService.getElencoAttivita(action.idProdotto);
    yield put(loadElencoAttivitaSuccess({listaAttivita: response}));
    yield put(disableLoader());
}

export function* loadConfigAsync() {
    const response: Configuration = yield apiService.getConfig();
    yield put(loadConfigSuccess({cfg: response}));
}
