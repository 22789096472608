import {put, select} from 'redux-saga/effects';
import {disableLoader, enableLoader, showAlert} from '../../actions/globalActions';
import {apiService} from '../../../services/ApiService';
import {QuotazioneReResponse, QuotazioneResponse} from '../../../models/Quotazione/QuotazioneResponse';
import {QUOTAZIONE_AUTO, QUOTAZIONE_CASA, QUOTAZIONE_INFORTUNI, QUOTAZIONE_MOTO, QUOTAZIONE_SALUTE, RIEPILOGO} from '../../../navigation/CONSTANTS';
import {historyHelp} from '../../../services/history';
import {
    calcolaQuotazioneReSuccess,
    calcolaQuotazioneSuccess,
    ricalcolaQuotazioneReFail,
    ricalcolaQuotazioneReSuccess,
    ricalcolaQuotazioneSuccess,
    ricalcolaQuotazioneVeicoloFail,
    salvaQuotazioneReSuccess,
    salvaQuotazioneSuccess,
} from '../../actions/quotazioneActions';
import {State} from '../../reducers';
import {ensure} from '../../../utils/commonUtil';
import {SalvaBaseRequest, SalvaQuotazioneRequest, SalvaReRequest} from '../../../models/Request/SalvaQuotazioneRequest';
import InputQuotazioneVeicolo from '../../../models/Input/InputQuotazioneVeicolo';
import {Offerta} from '../../../models/OfferteResponse';
import {ERROR_NO_MARCA_MODELLO} from '../../../services/CONSTANTS';
import InputQuotazioneCasa from "../../../models/Input/InputQuotazioneCasa";
import InputQuotazioneSalute from "../../../models/Input/InputQuotazioneSalute";
import InputQuotazioneInfortuni from "../../../models/Input/InputQuotazioneInfortuni";
import Anagrafica from "../../../models/Anagrafica";

export function* calcolaQuotazioneAsync(action: { type: string; payload: InputQuotazioneVeicolo }) {
    try {
        yield put(enableLoader());

        const offertaAuto: Offerta = yield select((s: State) => s.global.offerta);
        const response: QuotazioneResponse = yield apiService.calcola(action.payload, offertaAuto);

        if (response.errore?.codiceEsito === ERROR_NO_MARCA_MODELLO) {
            yield put(showAlert({warning: [{text: 'Nessun allestimento trovato: selezionarne uno manualmente'}]}));
            yield put(calcolaQuotazioneSuccess({res: response}));
            yield put(disableLoader());

        } else {
            // const idx = response.pacchetti?.findIndex((p: Pacchetto) => p.nomePacchetto === 'MEDIUM') || 0;
            // fixQuotazioneResponse(response, idx);
            yield put(calcolaQuotazioneSuccess({res: response, idx: 0}));
            yield put(disableLoader());
            if (response?.veicolo?.tipoVeicolo === 'A') {
                historyHelp.push(QUOTAZIONE_AUTO);
            } else {
                historyHelp.push(QUOTAZIONE_MOTO);
            }
        }

    } catch (e: any) {
        const message = typeof e === 'string' ? e : e.message;
        yield put(disableLoader());
        yield put(showAlert({danger: [{text: message}]}));
    }
}

export function* calcolaQuotazioneCasaAsync(action: { type: string; payload: InputQuotazioneCasa }) {
    try {
        yield put(enableLoader());
        const response: QuotazioneReResponse = yield apiService.calcolaCasa(action.payload);
        yield put(calcolaQuotazioneReSuccess({res: response}));
        yield put(disableLoader());
        historyHelp.push(QUOTAZIONE_CASA);
    } catch (e: any) {
        const message = typeof e === 'string' ? e : e.message;
        yield put(disableLoader());
        yield put(showAlert({danger: [{text: message}]}));
    }
}

export function* calcolaQuotazioneSaluteAsync(action: { type: string; payload: InputQuotazioneSalute }) {
    try {
        yield put(enableLoader());
        const response: QuotazioneReResponse = yield apiService.calcolaSalute(action.payload);
        yield put(calcolaQuotazioneReSuccess({res: response}));
        yield put(disableLoader());
        historyHelp.push(QUOTAZIONE_SALUTE);
    } catch (e: any) {
        const message = typeof e === 'string' ? e : e.message;
        yield put(disableLoader());
        yield put(showAlert({danger: [{text: message}]}));
    }
}

export function* calcolaQuotazioneInfortuniAsync(action: { type: string; payload: InputQuotazioneInfortuni }) {
    try {
        yield put(enableLoader());
        const response: QuotazioneReResponse = yield apiService.calcolaInfortuni(action.payload);
        yield put(calcolaQuotazioneReSuccess({res: response}));
        yield put(disableLoader());
        historyHelp.push(QUOTAZIONE_INFORTUNI);
    } catch (e: any) {
        const message = typeof e === 'string' ? e : e.message;
        yield put(disableLoader());
        yield put(showAlert({danger: [{text: message}]}));
    }
}

export function* ricalcolaQuotazioneAsync(action: { type: string; payload: {} }) {
    const state: State = yield select();
    // const idx = ensure(state.quotazione.pacchettoIdx);

    try {
        yield put(enableLoader());
        let q = ensure(state.quotazione.quotazioneResult);
        // let ricalcola = _.cloneDeep(q);
        // ricalcola.garanzie[3].importo = 10;
        // fixQuotazioneRequest(q, idx);
        //
        const response: QuotazioneResponse = yield apiService.ricalcola(q);
        // fixQuotazioneResponse(response, idx);
        yield put(ricalcolaQuotazioneSuccess(response));
        yield put(disableLoader());
    } catch (e: any) {
        yield put(ricalcolaQuotazioneVeicoloFail());
        const message = typeof e === 'string' ? e : e.message;
        yield put(disableLoader());
        yield put(showAlert({danger: [{text: message}]}));
    }
}

export function* ricalcolaQuotazioneReAsync(action: { type: string; payload: {} }) {
    const state: State = yield select();
    try {
        yield put(enableLoader());
        let q = ensure(state.quotazione.quotazioneReResult);
        const response: QuotazioneReResponse = yield apiService.ricalcolaRe(q);
        yield put(ricalcolaQuotazioneReSuccess(response));
        yield put(disableLoader());
    } catch (e: any) {
        yield put(ricalcolaQuotazioneReFail());
        const message = typeof e === 'string' ? e : e.message;
        yield put(disableLoader());
        yield put(showAlert({danger: [{text: message}]}));
    }
}

// export function* salvaQuotazioneAsync(action: {type: string; payload: {anagrafica: Anagrafica}}) {
export function* salvaQuotazioneAsync(action: { type: string; payload: { anagrafica: Anagrafica, agenzia: string } }) {
    const state: State = yield select();
    const quotazione = ensure(state.quotazione.quotazioneResult);

    try {
        yield put(enableLoader());
        quotazione.email = action.payload.anagrafica.email;
        const request = getSalvaBaseRequest(quotazione, action.payload.anagrafica, action.payload.agenzia);
        let requestVeicolo = (request) as SalvaQuotazioneRequest;
        requestVeicolo.quotazione = quotazione;
        const response: QuotazioneResponse = yield apiService.salva(requestVeicolo);
        yield put(salvaQuotazioneSuccess(response));
        yield put(disableLoader());
        historyHelp.push(RIEPILOGO);
    } catch (e: any) {
        const message = typeof e === 'string' ? e : e.message;
        yield put(disableLoader());
        yield put(showAlert({danger: [{text: message}]}));
    }

}

export function* salvaQuotazioneReAsync(action: { type: string; payload: { anagrafica: Anagrafica, agenzia: string } }) {
    const state: State = yield select();
    const quotazione = ensure(state.quotazione.quotazioneReResult);

    try {
        yield put(enableLoader());
        let request = getSalvaBaseRequest(quotazione, action.payload.anagrafica, action.payload.agenzia);
        let requestRe = (request) as SalvaReRequest;
        requestRe.quotazioneRe = quotazione;
        const response: QuotazioneReResponse = yield apiService.salvaRe(requestRe);
        yield put(salvaQuotazioneReSuccess(response));
        yield put(disableLoader());
        historyHelp.push(RIEPILOGO);
    } catch (e: any) {
        const message = typeof e === 'string' ? e : e.message;
        yield put(disableLoader());
        yield put(showAlert({danger: [{text: message}]}));
    }

}

const getSalvaBaseRequest = (quotazione: any, anagrafica: Anagrafica, agenzia: string) => {
    return {
        email: anagrafica.email,
        telefono: anagrafica.telefono,
        nome: anagrafica.nome,
        cognome: anagrafica.cognome,
        consensoMarketing: anagrafica.consensoMarketing ? 1 : 0,
        consensoProfilazione: anagrafica.consensoProfilazione ? 1 : 0,
        consensoSanitario: anagrafica.consensoSanitario ? 1 : 0,
        conAppuntamento: true,
        contraente: {
            nome: anagrafica.nome,
            cognome: anagrafica.cognome,
            email: anagrafica.email,
            codiceFiscale: anagrafica.codiceFiscale,
            etiposoggetto: "1"
        },
        agenziaAppuntamento: agenzia
    } as SalvaBaseRequest;
}


