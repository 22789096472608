import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {State} from '../../redux/reducers';
import {useHistory} from 'react-router-dom';
import {HOME_SALUTE} from '../../navigation/CONSTANTS';
import {Accordion, Card} from 'react-bootstrap';
import Garanzie from "./components/Garanzie";
import QuotazioneHeaderContainer from "./components/QuotazioneHeader/QuotazioneHeaderContainer";
import QuotazioneFooterContainer from "./components/QuotazioneFooterContainer";
import QuotazioneUtils from "../../utils/QuotazioneUtils";
import Copyright from "../../components/Copyright";
import {ricalcolaQuotazioneRe} from "../../redux/actions/quotazioneActions";
import {QuotazioneReResponse} from "../../models/Quotazione/QuotazioneResponse";

const QuotazioneSaluteContainer = ({quotazione, salvata, bloccaProsegui}: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [daRicalcolare, setDaRicalcolare] = useState(false);
    const [quotazioneUtils] = useState(new QuotazioneUtils(quotazione));

    const verificaQuotazione = () => {
        setDaRicalcolare(quotazioneUtils.verificaQuotazione(quotazione));
    }

    const ricalcola = () => {
        dispatch(ricalcolaQuotazioneRe());
        quotazioneUtils.init(quotazione);
        setDaRicalcolare(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!quotazione) {
            history.push(HOME_SALUTE);
        }
    }, [quotazione]);

    useEffect(() => {
        if (salvata) {
            history.push(HOME_SALUTE);
        }
    }, []);

    return (
        <>
            <QuotazioneHeaderContainer quotazione={quotazione}/>
            <div className='quotation-container base-container container'>
                <Card className="q-card">
                    <Card.Body className="body">
                        <Accordion alwaysOpen={false}>
                            <Garanzie garanzie={quotazione?.beni[0].garanzie} verificaQuotazione={verificaQuotazione} ricalcolaSottoGaranzie={ricalcola}/>
                        </Accordion>
                        <Copyright/>
                    </Card.Body>
                </Card>
            </div>
            <QuotazioneFooterContainer premio={quotazione?.importo} daRicalcolare={daRicalcolare} ricalcola={ricalcola} bloccaProsegui={bloccaProsegui}/>
        </>
    );
};

type Props = {
    quotazione: QuotazioneReResponse | null;
    salvata: boolean;
    bloccaProsegui: boolean;
};

const mapStateToProps = (state: State) => ({
    quotazione: state.quotazione.quotazioneReResult,
    salvata: state.quotazione.salvata,
    bloccaProsegui: state.quotazione.bloccaProsegui
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuotazioneSaluteContainer);
