import {Fattore, Garanzia} from "../models/Quotazione/QuotazioneResponse";
import _ from "lodash";

class QuotazioneUtils {
    private MAPPA_GARANZIE_SELEZIONATE: Map<string, boolean> = new Map();
    private MAPPA_VALORI_FATTORI: Map<string, Fattore> = new Map();

    constructor(quotazione: any) {
        this.init(quotazione);
    }

    init(quotazione: any) {
        let garanzie = QuotazioneUtils.getGaranzie(quotazione);
        this.MAPPA_GARANZIE_SELEZIONATE.clear();
        this.MAPPA_VALORI_FATTORI.clear();

        if (garanzie.length > 0) {
            for (let garanzia of garanzie) {
                this.MAPPA_GARANZIE_SELEZIONATE.set(garanzia.codiceGaranziaSelezionata, garanzia.selezionata);
                if (garanzia?.fattori?.length > 0) {
                    for (let fattore of garanzia.fattori) {
                        this.MAPPA_VALORI_FATTORI.set("" + garanzia.codiceGaranziaSelezionata + fattore.codice, _.cloneDeep(fattore))
                    }
                }
            }
        }
    }

    verificaQuotazione(quotazione: any) {
        let result = false;
        let garanzie = QuotazioneUtils.getGaranzie(quotazione);
        for (let garanzia of garanzie) {
            result = this.checkIfSelected(garanzia)
            if (result) {
                return result
            }
        }
        return result;
    }

    checkIfSelected = (garanzia: any) => {
        if (this.MAPPA_GARANZIE_SELEZIONATE.get(garanzia.codiceGaranziaSelezionata) !== garanzia.selezionata) {
            return true;
        } else if (garanzia.selezionata && garanzia?.fattori?.length > 0) {
            for (let fattore of garanzia.fattori) {
                if (this.MAPPA_VALORI_FATTORI.get(garanzia.codiceGaranziaSelezionata + fattore.codice)?.codiceValoreSelezionato !== fattore.codiceValoreSelezionato) {
                    return true;
                }
            }
        }
        return false
    }

    static getGaranzie(quotazione: any) {
        return quotazione?.garanzie ? quotazione.garanzie : this.getGaranzieByBeni(quotazione);
    }

    static getGaranzieByBeni(quotazione: any) {
        let listaGaranzie: Garanzia[] = [];
        for (let bene of quotazione?.beni || []) {
            listaGaranzie = listaGaranzie.concat(bene.garanzie);
        }
        return listaGaranzie;
    }

    static getGaranziaByCode(bene: any, code: string) {
        return bene.garanzie.find((garanzia: Garanzia) => garanzia.codice === code);
    }
}

export default QuotazioneUtils;
