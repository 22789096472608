import {Fattore} from "../../../models/Quotazione/QuotazioneResponse";
import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Util from "../../../utils/commonUtil";

const FattoreView = ({fattore}: Props) => {
    const [valore, setValore] = useState<string | undefined>();

    useEffect(() => {
        switch (fattore.tipofattore) {
            case 'S':
                if (fattore.valori && fattore.valori.length > 0) {
                    for (let v of fattore.valori) {
                        if (v.codice === fattore.codiceValoreSelezionato) {
                            setValore(v.descrizione);
                            break;
                        }
                    }
                }
                break;
            case 'T':
            case 'I':
                setValore(Util.formattaImporto(fattore.valore));
                break;
        }
    }, [])

    return <Row>
        <Col xs={12} sm={6}>{fattore.descrizione}</Col>
        <Col xs={12} sm={6}>{valore}</Col>
    </Row>
}

type Props = {
    fattore: Fattore
}
export default FattoreView;
