import Agenzia from "../../../models/Agenzia";
import {Button, Col, Container, Row} from "react-bootstrap";
import React from "react";

const AgenziaContainer = ({agenzia, setAgenzia}: Props) => {

    const resetAgenzia = () => {
        setAgenzia(undefined);
    }

    return <div className="agenzia-container">
        <Container><Row>
            <Col xs={12} sm={6}>
                <div className="subtitle">Agenzia scelta</div>
                <div className="name">Agenzia di {agenzia.citta}</div>
                <div className="subtitle">{agenzia.indirizzo}</div>
                <div className="subtitle">{agenzia.citta} ({agenzia.provincia}) - {agenzia.cap}</div>
            </Col>
            <Col xs={12} sm={6} className="button-col">
                <Button className="q-button q-button-primary q-home-button" onClick={resetAgenzia}>CAMBIA AGENZIA</Button>
            </Col>
        </Row></Container>
    </div>
}

type Props = {
    agenzia: Agenzia,
    setAgenzia: (value: any) => void
}
export default AgenziaContainer;
