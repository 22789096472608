export const ROOT = '/';
export const LOGIN = '/login';
export const HOME_AUTO = '/auto';
export const EXTERNAL_HOME_AUTO = '/externalAuto/:btoa';
export const QUOTAZIONE_AUTO = '/auto/quotazione';
export const HOME_MOTO = '/moto';
export const EXTERNAL_HOME_MOTO = '/externalMoto/:btoa';
export const QUOTAZIONE_MOTO = '/moto/quotazione';
export const HOME_CASA = '/casa';
export const EXTERNAL_HOME_CASA = '/externalCasa/:btoa';
export const QUOTAZIONE_CASA = '/casa/quotazione';
export const HOME_SALUTE = '/salute';
export const EXTERNAL_HOME_SALUTE = '/externalSalute/:btoa';
export const QUOTAZIONE_SALUTE = '/salute/quotazione';
export const HOME_INFORTUNI = '/infortuni';
export const EXTERNAL_HOME_INFORTUNI = '/externalInfortuni/:btoa';
export const QUOTAZIONE_INFORTUNI = '/infortuni/quotazione';
export const SALVATAGGIO = '/salvataggio'
export const RIEPILOGO = '/riepilogo';
