import React from "react";
import {Proprietario} from "../../../../models/Quotazione/QuotazioneResponse";
import userIcon from "../../../../assets/images/person-circle.svg";
import moment from "moment";

const QuotazioneHeaderProprietario = ({proprietario}: Props) => {
    return <div className="desktop-view">
        <div className="d-flex tile">
            <img className="logo" src={userIcon} alt=""/>
            <div>
                <div>{proprietario.cognome} {proprietario.nome}</div>
                <div>{proprietario.comuneResidenza} ({proprietario.provinciaResidenza}) { moment(proprietario.dataNascita).format('DD/MM/YYYY')}</div>
            </div>
        </div>
    </div>
}

type Props = {
    proprietario: Proprietario
}
export default QuotazioneHeaderProprietario
