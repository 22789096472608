import infoIcon from "../../../assets/images/info-circle.svg";
import React, {useState} from "react";
import {InfoHelp} from "../../../models/InfoHelp";
import {Button, Modal} from "react-bootstrap";

import "../../../assets/scss/Helper.scss"

type HelperProps = {
    help: InfoHelp | undefined
}

const Helper = ({help}: HelperProps) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <>
        {help !== undefined &&
		<span className={"helperIcon"}>
            <Modal show={show} onHide={handleClose} size="lg" dialogClassName="modal-veicolo">
                <Modal.Body>
                    <h2>{help.titolo}</h2>
                    <div dangerouslySetInnerHTML={{__html: help.testo}}/>
                    <Button variant="primary" onClick={handleClose}>OK</Button>
                </Modal.Body>
            </Modal>
            <img className="info-icon pointer" src={infoIcon} onClick={handleShow} alt="Help"/>
        </span>}
    </>
};

export default Helper;
