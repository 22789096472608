import {AllestimentoRequest} from '../../models/Veicolo/Allestimento';
import {Offerta} from '../../models/OfferteResponse';
import {AlertMessages} from "../../models/AlertMessages";
import {InfoHelp} from "../../models/InfoHelp";
import {Configuration} from "../../models/Configuration";

export enum GlobalActionTypes {
    ENABLE_LOADER = '[GlobalState] ENABLE_LOADER',
    DISABLE_LOADER = '[GlobalState] DISABLE_LOADER',

    SHOW_ANAGRAFICA_DIALOG = '[GlobalState] SHOW_ANAGRAFICA_DIALOG',
    CLOSE_ANAGRAFICA_DIALOG = '[GlobalState] CLOSE_ANAGRAFICA_DIALOG',

    SHOW_ALERT = '[GlobalState] SHOW_ALERT',
    CLOSE_ALERT = '[GlobalState] CLOSE_ALERT',

    LOAD_COOKIE = '[GlobalState] LOAD_COOKIE',
    LOAD_OFFERTA_AUTO = '[GlobalState] LOAD_OFFERTA_AUTO',
    LOAD_OFFERTA_AUTO_SUCCESS = '[GlobalState] LOAD_OFFERTA_AUTO_SUCCESS',
    LOAD_INFO_HELP = '[GlobalState] LOAD_INFO_HELP',
    LOAD_INFO_HELP_RE = '[GlobalState] LOAD_INFO_HELP_RE',
    LOAD_INFO_HELP_SUCCESS = '[GlobalState] LOAD_INFO_HELP_SUCCESS',

    LOAD_MARCHE_QR = '[GlobalState] LOAD_MARCHE_QR',
    LOAD_MARCHE_QR_SUCCESS = '[GlobalState] LOAD_MARCHE_QR_SUCCESS',
    LOAD_MODELLI_QR = '[GlobalState] LOAD_MODELLI_QR',
    LOAD_MODELLI_QR_SUCCESS = '[GlobalState] LOAD_MODELLI_QR_SUCCESS',
    LOAD_ALLESTIMENTI_QR = '[GlobalState] LOAD_ALLESTIMENTI_QR',
    LOAD_ALLESTIMENTI_QR_SUCCESS = '[GlobalState] LOAD_ALLESTIMENTI_QR_SUCCESS',

    LOAD_MARCHE_DR = '[GlobalState] LOAD_MARCHE_DR',
    LOAD_MARCHE_DR_SUCCESS = '[GlobalState] LOAD_MARCHE_DR_SUCCESS',
    LOAD_MODELLI_DR = '[GlobalState] LOAD_MODELLI_DR',
    LOAD_MODELLI_DR_SUCCESS = '[GlobalState] LOAD_MODELLI_DR_SUCCESS',
    LOAD_ALLESTIMENTI_DR = '[GlobalState] LOAD_ALLESTIMENTI_DR',
    LOAD_ALLESTIMENTI_DR_SUCCESS = '[GlobalState] LOAD_ALLESTIMENTI_DR_SUCCESS',

    BLOCCA_QUOTAZIONE = '[GlobalState] BLOCCA_QUOTAZIONE',

    LOAD_ELENCO_ATTIVITA = '[GlobalState] LOAD_ELENCO_ATTIVITA',
    LOAD_ELENCO_ATTIVITA_SUCCESS = '[GlobalState] LOAD_ELENCO_ATTIVITA_SUCCESS',

    SET_BACKGROUND_AUTO = '[GlobalState] SET_BACKGROUND_AUTO',
    SET_BACKGROUND_INFORTUNI = '[GlobalState] SET_BACKGROUND_INFORTUNI',
    SET_BACKGROUND_SALUTE = '[GlobalState] SET_BACKGROUND_SALUTE',
    SET_BACKGROUND_CASA = '[GlobalState] SET_BACKGROUND_CASA',

    LOAD_CONFIG = '[GlobalState] LOAD_CONFIG',
    LOAD_CONFIG_SUCCESS = '[GlobalState] LOAD_CONFIG_SUCCESS',

    READY = '[GlobalState] READY',
}

export const showAnagraficaDialog = () => {
    return {
        type: GlobalActionTypes.SHOW_ANAGRAFICA_DIALOG,
    };
};

export const closeAnagraficaDialog = () => {
    return {
        type: GlobalActionTypes.CLOSE_ANAGRAFICA_DIALOG,
    };
};

export const loadCookie = (quotationType: string) => {
    return {
        type: GlobalActionTypes.LOAD_COOKIE,
        quotationType: quotationType,
    };
};

export const loadOffertaAuto = () => {
    return {
        type: GlobalActionTypes.LOAD_OFFERTA_AUTO,
    };
};
export const loadOffertaAutoSuccess = (payload: { offerta: Offerta }) => {
    return {
        type: GlobalActionTypes.LOAD_OFFERTA_AUTO_SUCCESS,
        payload,
    };
};

export const loadInfoHelp = () => {
    return {
        type: GlobalActionTypes.LOAD_INFO_HELP,
    };
};
export const loadInfoReHelp = (codiceProdotto: string) => {
    return {
        type: GlobalActionTypes.LOAD_INFO_HELP_RE,
        codiceProdotto,
    };
};
export const loadInfoHelpSuccess = (payload: { infoHelpMap: Map<string, InfoHelp> }) => {
    return {
        type: GlobalActionTypes.LOAD_INFO_HELP_SUCCESS,
        payload,
    };
};

export const showAlert = (payload: AlertMessages) => {
    return {
        type: GlobalActionTypes.SHOW_ALERT,
        payload,
    };
};
export const closeAlert = (payload: { messages: AlertMessages }) => {
    return {
        type: GlobalActionTypes.CLOSE_ALERT,
        payload,
    };
};

export const enableLoader = () => {
    return {
        type: GlobalActionTypes.ENABLE_LOADER,
    };
};
export const disableLoader = () => {
    return {
        type: GlobalActionTypes.DISABLE_LOADER,
    };
};

export const loadMarcheQr = () => {
    return {
        type: GlobalActionTypes.LOAD_MARCHE_QR,
    };
};
export const loadMarcheQrSuccess = (payload: { marche: any[] }) => {
    return {
        type: GlobalActionTypes.LOAD_MARCHE_QR_SUCCESS,
        payload,
    };
};
export const loadModelliQr = (payload: { marca: string; tipoVeicolo?: string }) => {
    return {
        type: GlobalActionTypes.LOAD_MODELLI_QR,
        payload,
    };
};
export const loadModelliQrSuccess = (payload: { modelli: any[] }) => {
    return {
        type: GlobalActionTypes.LOAD_MODELLI_QR_SUCCESS,
        payload,
    };
};
export const loadAllestimentiQr = (payload: AllestimentoRequest) => {
    return {
        type: GlobalActionTypes.LOAD_ALLESTIMENTI_QR,
        payload,
    };
};
export const loadAllestimentiQrSuccess = (payload: { allestimenti: any[] }) => {
    return {
        type: GlobalActionTypes.LOAD_ALLESTIMENTI_QR_SUCCESS,
        payload,
    };
};

export const loadMarcheDr = () => {
    return {
        type: GlobalActionTypes.LOAD_MARCHE_DR,
    };
};
export const loadMarcheDrSuccess = (payload: { marche: any[] }) => {
    return {
        type: GlobalActionTypes.LOAD_MARCHE_DR_SUCCESS,
        payload,
    };
};
export const loadModelliDr = (payload: { marca: string; tipoVeicolo?: string }) => {
    return {
        type: GlobalActionTypes.LOAD_MODELLI_DR,
        payload,
    };
};
export const loadModelliDrSuccess = (payload: { modelli: any[] }) => {
    return {
        type: GlobalActionTypes.LOAD_MODELLI_DR_SUCCESS,
        payload,
    };
};
export const loadAllestimentiDr = (payload: AllestimentoRequest) => {
    return {
        type: GlobalActionTypes.LOAD_ALLESTIMENTI_DR,
        payload,
    };
};
export const loadAllestimentiDrSuccess = (payload: { allestimenti: any[] }) => {
    return {
        type: GlobalActionTypes.LOAD_ALLESTIMENTI_DR_SUCCESS,
        payload,
    };
};

export const bloccaQuotazione = () => {
    return {
        type: GlobalActionTypes.BLOCCA_QUOTAZIONE,
    };
}

export const loadElencoAttivita = (idProdotto: number) => {
    return {
        type: GlobalActionTypes.LOAD_ELENCO_ATTIVITA,
        idProdotto,
    };
}

export const loadElencoAttivitaSuccess = (payload: { listaAttivita: any[] }) => {
    return {
        type: GlobalActionTypes.LOAD_ELENCO_ATTIVITA_SUCCESS,
        payload,
    };
}
export const setBackgroundInfortuni = () => {
    return {
        type: GlobalActionTypes.SET_BACKGROUND_INFORTUNI,
    };
};
export const setBackgroundCasa = () => {
    return {
        type: GlobalActionTypes.SET_BACKGROUND_CASA,
    };
};
export const setBackgroundSalute = () => {
    return {
        type: GlobalActionTypes.SET_BACKGROUND_SALUTE,
    };
};
export const setBackgroundAuto = () => {
    return {
        type: GlobalActionTypes.SET_BACKGROUND_AUTO,
    };
};

export const loadConfig = () => {
    return {
        type: GlobalActionTypes.LOAD_CONFIG,
    };
};

export const loadConfigSuccess = (payload: { cfg: Configuration }) => {
    return {
        type: GlobalActionTypes.LOAD_CONFIG_SUCCESS,
        payload
    };
};

export const ready = () => {
    return {
        type: GlobalActionTypes.READY,
    };
}
