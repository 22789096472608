import React, {useEffect, useState} from 'react';
import logoTua from '../../assets/images/logoTua.png';
import logoMoto from '../../assets/images/motorbike-front-fill.svg';
import {useDispatch, useSelector} from 'react-redux';
import {disableLoader, enableLoader, loadCookie, setBackgroundAuto, showAlert} from '../../redux/actions/globalActions';
import {Button, Card, FloatingLabel, Form} from 'react-bootstrap';
import {calcolaQuotazione} from '../../redux/actions/quotazioneActions';
import {QuotationType} from '../../models/Quotazione/QuotationType';
import {State} from '../../redux/reducers';
import moment from 'moment';
import Copyright from "../../components/Copyright";
import {Marca} from "../../models/Veicolo/Marche";
import {Modello} from "../../models/Veicolo/Modelli";
import {Allestimento, AllestimentoRequest} from "../../models/Veicolo/Allestimento";
import {apiService} from "../../services/ApiService";
import Select from "../../components/Select";
import {extractExternalInput} from "../../utils/commonUtil";
import {HOME_MOTO} from "../../navigation/CONSTANTS";
import {useHistory} from "react-router-dom";
import {updateUser} from "../../redux/actions/userActions";

const HomeMotoContainer = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [quotationType] = useState<QuotationType>('moto');
    const bloccaQuotazione: boolean = useSelector((state: State) => state.global.bloccaQuotazione);
    const isReady: boolean = useSelector((state: State) => state.global.isReady);
    const [targa, setTarga] = useState("");
    const [email, setEmail] = useState("");
    const [dataNascitaProprietario, setDataNascitaProprietario] = useState<Date | null>(null);
    const [marca, setMarca] = useState<Marca>();
    const listaMarche: Marca[] = useSelector((state: State) => state.global.marcheDR);
    const [modello, setModello] = useState<Modello>()
    const [listaModelli, setListaModelli] = useState<Modello[]>();
    const [allestimento, setAllestimento] = useState<Allestimento>();
    const [listaAllestimenti, setListaAllestimenti] = useState<Allestimento[]>();
    const localStorageVar = "params_moto"

    const changeMarca = (marca: Marca) => {
        setMarca(marca);
        changeModello(undefined);
    };
    const changeModello = (modello: Modello | undefined) => {
        setModello(modello);
        setListaAllestimenti(undefined);
        changeAllestimento(undefined);
    };
    const changeAllestimento = (allestimento: Allestimento | undefined) => {
        setAllestimento(allestimento);
    };

    useEffect(() => {

        dispatch(setBackgroundAuto())
        if (props.match.path.startsWith("/externalMoto")) {
            let params = extractExternalInput(props.match.params.btoa);
            if (params.tar && params.dn) {
                localStorage.setItem(localStorageVar, JSON.stringify({
                    targa: params.tar,
                    dataNascitaProprietario: params.dn,
                    email: params.em
                }))
                history.push(HOME_MOTO);
            } else {
                history.push(HOME_MOTO);
            }
        }
    }, []);

    useEffect(() => {
        dispatch(loadCookie(quotationType));
    }, [dispatch]);

    useEffect(() => {
        if (marca?.codice != null) {
            dispatch(enableLoader());
            apiService.getModelliDR(marca.codice, 'M').then((response) => {
                setListaModelli(response);
            }).catch((err) => {
                dispatch(showAlert({danger: [{text: "Errore durante il caricamento dei Modelli"}]}))
            }).finally(() => {
                dispatch(disableLoader());
            })
        }
    }, [marca]);

    useEffect(() => {
        if (modello?.codice != null) {
            dispatch(enableLoader());
            apiService.getAllestimentiDR({
                marca: marca?.codice,
                modello: modello.codice
            } as AllestimentoRequest).then((response) => {
                setListaAllestimenti(response);
            }).catch((err) => {
                dispatch(showAlert({danger: [{text: "Errore durante il caricamento degli Allestimenti"}]}))
            }).finally(() => {
                dispatch(disableLoader());
            })
        }
    }, [modello]);

    useEffect(() => {
        const localStorageObj = localStorage.getItem(localStorageVar);
        let state = null;
        if (localStorageObj != null) {
            state = JSON.parse(localStorageObj)
            if (state != null && isReady) {
                let input = state;
                setTarga(input.targa);
                setDataNascitaProprietario(moment(input.dataNascitaProprietario, 'DD/MM/YYYY').toDate())
                setEmail(input.email);

                localStorage.removeItem(localStorageVar)
                history.replace(HOME_MOTO);
            }
        }
    }, [isReady]);

    useEffect(() => {
        dispatch(updateUser({
            email: email
        }))
    }, [email])

    const quota = () => {
        if (targa && dataNascitaProprietario && marca && modello && allestimento) {
            dispatch(calcolaQuotazione({
                email,
                targa,
                dataNascitaProprietario,
                marca,
                modello,
                allestimento,
                quotationType
            }));
        }
    };

    const disableCalcolaButton = () => {
        return !(targa && dataNascitaProprietario && marca && modello && allestimento);
    };

    return (
        <div className="base-container container home-container">
            <Card className="q-card">
                <Card.Body className="p-0">
                    <div className="row ms-0 me-0">
                        <div className="col-md-4 p-3 logo-container">
                            <img className="logo-company logo-tua" src={logoTua} alt=""/>

                            <div
                                className="d-flex flex-column align-items-center justify-content-start introduction-container">
                                <div className="introduction">
                                    Calcola la tua quotazione <br/>
                                    <span className="product">MOTO</span>
                                </div>
                                <img className="logo-product" style={{width: "58px"}} src={logoMoto} alt=""/>
                            </div>
                        </div>

                        <div className="col-md-8 form-container">
                            <Form className="q-form" autoComplete="off" noValidate>
                                <FloatingLabel className="mb-3" controlId="targa" label="Targa">
                                    <Form.Control
                                        type="text"
                                        value={targa}
                                        style={{textTransform: 'uppercase'}}
                                        placeholder="Targa"
                                        disabled={bloccaQuotazione}
                                        onChange={(v) => setTarga(v.currentTarget.value)}
                                    />
                                </FloatingLabel>

                                <FloatingLabel
                                    className="mb-3"
                                    controlId="dataNascitaProprietario"
                                    label="Data di nascita proprietario"
                                >
                                    <Form.Control
                                        required
                                        type="date"
                                        value={moment(dataNascitaProprietario).format("YYYY-MM-DD")}
                                        disabled={bloccaQuotazione}
                                        onChange={(v) => setDataNascitaProprietario(moment(v.currentTarget.value, 'YYYY/MM/DD').toDate())}
                                    />
                                </FloatingLabel>

                                <Select options={listaMarche} label={'Marca'} selectedValue={marca}
                                        disabled={bloccaQuotazione}
                                        onChange={(value) => changeMarca({codice: value} as Marca)}/>

                                <Select options={listaModelli} label={'Modello'} selectedValue={modello}
                                        disabled={marca?.codice == null}
                                        onChange={(value) => changeModello({codice: value} as Modello)}/>

                                <Select options={listaAllestimenti} label={'Allestimento'} selectedValue={allestimento}
                                        disabled={modello?.codice == null}
                                        onChange={(value) => changeAllestimento({codice: value} as Allestimento)}/>

                                <FloatingLabel className="mb-3" controlId="email" label="Email">
                                    <Form.Control type="email" value={email} placeholder="email"
                                                  onChange={(v) => setEmail(v.currentTarget.value)}
                                                  disabled={bloccaQuotazione}/>
                                </FloatingLabel>

                                <div className="mt-4 d-flex justify-content-center">
                                    <div style={{cursor: disableCalcolaButton() ? 'not-allowed' : 'pointer'}}>
                                        <Button
                                            className="q-button q-button-primary q-home-button"
                                            disabled={disableCalcolaButton()}
                                            onClick={quota}
                                        >
                                            CALCOLA
                                        </Button>
                                    </div>
                                </div>
                            </Form>

                            <Copyright/>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default HomeMotoContainer;
