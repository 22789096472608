import {GlobalActionTypes} from '../actions/globalActions';
import {Offerta} from '../../models/OfferteResponse';
import {AlertMessages} from "../../models/AlertMessages";
import {Attivita} from "../../models/Quotazione/Attivita";
import {InfoHelp} from "../../models/InfoHelp";
import {Configuration} from "../../models/Configuration";

export interface GlobalState {
    pendingRequest: number;
    showAnagraficaDialog: boolean;
    alert: AlertMessages,
    offerta: Offerta | null;
    infoHelpMap: Map<string, InfoHelp>;
    marcheQR: any[];
    modelliQR: any[];
    allestimentiQR: any[];
    marcheDR: any[];
    modelliDR: any[];
    allestimentiDR: any[];
    listaAttivita: Attivita[];
    bloccaQuotazione: boolean;
    backgroundStyle: string;
    isReady: boolean;
    configuration: Configuration;
}

const initialState: GlobalState = {
    pendingRequest: 0,
    showAnagraficaDialog: false,
    alert: {
        info: [],
        danger: [],
        warning: []
    },
    offerta: null,
    infoHelpMap: new Map(),
    marcheQR: [],
    modelliQR: [],
    allestimentiQR: [],
    marcheDR: [],
    modelliDR: [],
    allestimentiDR: [],
    listaAttivita: [],
    bloccaQuotazione: false,
    backgroundStyle: "",
    isReady: false,
    configuration: {locatorUrl: "", homePage: ""}
};

const globalReducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GlobalActionTypes.SHOW_ALERT:
            return {
                ...state,
                alert: {
                    info: action.payload.info?.length > 0 ? [...action.payload.info] : state.alert.info,
                    danger: action.payload.danger?.length > 0 ? [...action.payload.danger] : state.alert.danger,
                    warning: action.payload.warning?.length > 0 ? [...action.payload.warning] : state.alert.warning
                },
            };
        case GlobalActionTypes.SHOW_ANAGRAFICA_DIALOG:
            return {
                ...state,
                showAnagraficaDialog: true,
            };
        case GlobalActionTypes.CLOSE_ANAGRAFICA_DIALOG:
            return {
                ...state,
                showAnagraficaDialog: false,
            };
        case GlobalActionTypes.CLOSE_ALERT:
            return {
                ...state,
                alert: {...action.payload.messages},
            };
        case GlobalActionTypes.ENABLE_LOADER:
            return {
                ...state,
                pendingRequest: state.pendingRequest + 1
            };
        case GlobalActionTypes.DISABLE_LOADER:
            return {
                ...state,
                pendingRequest: state.pendingRequest - 1
            };
        case GlobalActionTypes.LOAD_OFFERTA_AUTO_SUCCESS:
            return {
                ...state,
                offerta: action.payload.offerta
            };
        case GlobalActionTypes.LOAD_INFO_HELP_SUCCESS:
            return {
                ...state,
                infoHelpMap: action.payload.infoHelpMap,
            };
        case GlobalActionTypes.LOAD_MARCHE_QR_SUCCESS:
            return {
                ...state,
                marcheQR: action.payload.marche,
            };
        case GlobalActionTypes.LOAD_MODELLI_QR_SUCCESS:
            return {
                ...state,
                modelliQR: action.payload.modelli,
            };
        case GlobalActionTypes.LOAD_ALLESTIMENTI_QR_SUCCESS:
            return {
                ...state,
                allestimentiQR: action.payload.allestimenti,
            };
        case GlobalActionTypes.LOAD_MARCHE_DR_SUCCESS:
            return {
                ...state,
                marcheDR: action.payload.marche,
            };
        case GlobalActionTypes.LOAD_MODELLI_DR_SUCCESS:
            return {
                ...state,
                modelliDR: action.payload.modelli,
                allestimentiDR: [],
            };
        case GlobalActionTypes.LOAD_ALLESTIMENTI_DR_SUCCESS:
            return {
                ...state,
                allestimentiDR: action.payload.allestimenti,
            };
        case GlobalActionTypes.BLOCCA_QUOTAZIONE:
            return {
                ...state,
                bloccaQuotazione: true
            }
        case GlobalActionTypes.LOAD_ELENCO_ATTIVITA_SUCCESS:
            return {
                ...state,
                listaAttivita: action.payload.listaAttivita
            }
        case GlobalActionTypes.SET_BACKGROUND_AUTO:
            return {
                ...state,
                backgroundStyle: "auto_bg"
            }
        case GlobalActionTypes.SET_BACKGROUND_SALUTE:
            return {
                ...state,
                backgroundStyle: "salute_bg"
            }
        case GlobalActionTypes.SET_BACKGROUND_INFORTUNI:
            return {
                ...state,
                backgroundStyle: "infortuni_bg"
            }
        case GlobalActionTypes.SET_BACKGROUND_CASA:
            return {
                ...state,
                backgroundStyle: "casa_bg"
            }
        case GlobalActionTypes.LOAD_CONFIG_SUCCESS:
            return {
                ...state,
                configuration: action.payload.cfg
            }
        case GlobalActionTypes.READY:
            return {
                ...state,
                isReady: true
            }
        default:
            return state;
    }
};

export default globalReducer;
