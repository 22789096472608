import React, {ChangeEvent} from 'react';
import {Fattore, Valore} from "../../../models/Quotazione/QuotazioneResponse";
import {Col, Container, Form, Row} from "react-bootstrap";
import Util from "../../../utils/commonUtil";

type FattoreItemProps = {
    fattore: Fattore;
    verificaQuotazione: () => void;
};

const FattoreItemSelect = ({fattore, verificaQuotazione}: FattoreItemProps) => {
    const useValueChange = (event: ChangeEvent<HTMLSelectElement>) => {
        fattore.codiceValoreSelezionato = event.target.value;
        verificaQuotazione()
    };
    return (
        <Form.Select defaultValue={fattore.codiceValoreSelezionato} onChange={useValueChange} disabled={fattore.nonModificabile}>
            {fattore.valori?.map((valore: Valore) => (<option key={valore.codice} value={valore.codice}>{valore.descrizione}</option>))}
        </Form.Select>
    )
}

const FattoreItemImporto = ({fattore, verificaQuotazione}: FattoreItemProps) => {
    return (
        <Form.Control type="text" defaultValue={Util.formattaImporto(fattore.valore)} placeholder={fattore.descrizione} disabled={true}/>
    )
}

const FattoreItem = ({fattore, verificaQuotazione}: FattoreItemProps) => {
    return (
        <Col xs={12} sm={8} md={6}>
            <Form>
                <Form.Group className="factor">
                    <Form.Label>{fattore.descrizione}</Form.Label>
                    {fattore.tipofattore === 'S' && <FattoreItemSelect fattore={fattore} verificaQuotazione={verificaQuotazione}/>}
                    {(fattore.tipofattore === 'T' || fattore.tipofattore === 'I') && <FattoreItemImporto fattore={fattore} verificaQuotazione={verificaQuotazione}/>}
                </Form.Group>
            </Form>
        </Col>
    )
}

type Props = {
    fattori: Fattore[];
    verificaQuotazione: () => void;
};

const Fattori = ({fattori, verificaQuotazione}: Props) => {

    return <Container className="factors-container"><Row>{(fattori.map((f, index) =>
        (
            f.visibileInPreventivo && f.tipofattore && <FattoreItem fattore={f} verificaQuotazione={verificaQuotazione} key={index + "_" + f.codice} />
        )))
    }</Row>
    </Container>

}

export default Fattori;
