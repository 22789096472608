import {put} from 'redux-saga/effects';
import {
  disableLoader,
  enableLoader,
  loadAllestimentiDrSuccess,
  loadAllestimentiQrSuccess,
  loadMarcheDrSuccess,
  loadMarcheQrSuccess,
  loadModelliDrSuccess,
  loadModelliQrSuccess,
} from '../../actions/globalActions';
import {apiService} from '../../../services/ApiService';
import {AllestimentoRequest} from '../../../models/Veicolo/Allestimento';

export function* loadMarcheQRAsync() {
  yield put(enableLoader());
  const response: any[] = yield apiService.getMarcheQR();
  yield put(loadMarcheQrSuccess({marche: response}));
  yield put(disableLoader());
}

export function* loadModelliQRAsync(action: {type: string; payload: {marca: string; tipoVeicolo: string}}) {
  yield put(enableLoader());
  const {marca, tipoVeicolo} = action.payload;
  const response: any[] = yield apiService.getModelliQR(marca, tipoVeicolo);
  yield put(loadModelliQrSuccess({modelli: response}));
  yield put(disableLoader());
}

export function* loadAllestimentiQRAsync(action: {type: string; payload: AllestimentoRequest}) {
  yield put(enableLoader());
  const response: any[] = yield apiService.getAllestimentiQR(action.payload);
  yield put(loadAllestimentiQrSuccess({allestimenti: response}));
  yield put(disableLoader());
}

export function* loadMarcheDRAsync() {
  yield put(enableLoader());
  const response: any[] = yield apiService.getMarcheDR();
  yield put(loadMarcheDrSuccess({marche: response}));
  yield put(disableLoader());
}

export function* loadModelliDRAsync(action: {type: string; payload: {marca: string; tipoVeicolo: string}}) {
  yield put(enableLoader());
  const {marca, tipoVeicolo} = action.payload;
  const response: any[] = yield apiService.getModelliDR(marca, tipoVeicolo);
  yield put(loadModelliDrSuccess({modelli: response}));
  yield put(disableLoader());
}

export function* loadAllestimentiDRAsync(action: {type: string; payload: AllestimentoRequest}) {
  yield put(enableLoader());
  const response: any[] = yield apiService.getAllestimentiDR(action.payload);
  yield put(loadAllestimentiDrSuccess({allestimenti: response}));
  yield put(disableLoader());
}
