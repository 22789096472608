import React, {useEffect} from 'react';
// Handle console logs
import './utils/dropConsole';

// ROUTER
import {BrowserRouter, HashRouter, useHistory} from 'react-router-dom';
import {RouterConfig} from './navigation/RouterConfig';
// MUI Theme
import './assets/scss/App.scss';
// Redux
import {Provider} from 'react-redux';
import store from './redux/store';
import CLoader from './components/CLoader';
import Messages from "./components/AlertContainer";
import BackGround from "./components/BackGround";

const App = () => {
    return (
        <div className={'app'}>
            <div>
                <Provider store={store}>
                    <CLoader/>
                    <BackGround />
                    <Messages/>
                    <RouterConfig/>
                </Provider>
            </div>
        </div>
    );
};

export default App;
