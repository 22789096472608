import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import icon from "../../../../assets/images/logo_infortuni.png";
import moment from "moment";

const QuotazioneHeaderInfortuniDettaglio = ({dataNascita, attivita, indirizzo}: Props) => {
    return <>
        <div className="d-flex modal-veicolo-header tile">
            <img className="logo" src={icon} alt="" />
            <div>
                <div className="q-txt-medium q-bold">DATI ASSICURATO</div>
            </div>
        </div>

        <Container className="modal-veicolo-body">
            <Row>
                <Col xs="12" sm="4">
                    <div className="q-bold">Data di nascita</div>
                    <div>{moment(dataNascita).format('DD/MM/YYYY')}</div>
                </Col>
                <Col xs="12" sm="4">
                    <div className="q-bold">Tipo di impiego</div>
                    <div>{attivita}</div>
                </Col>
                <Col xs="12">
                    <div className="q-bold">Indirizzo</div>
                    <div>{indirizzo}</div>
                </Col>
            </Row>
        </Container>
    </>
}

type Props = {
    dataNascita: string, attivita: string | undefined, indirizzo: string
}
export default QuotazioneHeaderInfortuniDettaglio;
