import {useDispatch, useSelector} from "react-redux";
import {QuotationType} from "../../models/Quotazione/QuotationType";
import React, {useEffect, useState} from "react";
import {State} from "../../redux/reducers";
import {loadCookie, setBackgroundCasa} from "../../redux/actions/globalActions";
import {calcolaQuotazioneCasa} from "../../redux/actions/quotazioneActions";
import {Button, Card, FloatingLabel, Form} from "react-bootstrap";
import logoTua from "../../assets/images/logoTua.png";
import logoCasa from "../../assets/images/house-fill.svg";
import moment from "moment/moment";
import Copyright from "../../components/Copyright";
import Util, {extractExternalInput} from "../../utils/commonUtil";
import {HOME_CASA} from "../../navigation/CONSTANTS";
import {OFFERTA_ACTIVE_CASA} from "../../services/CONSTANTS";
import {TipologiaAbitazione, TipologiaProprietario} from "../../models/OffertaActiveCasa";
import {useHistory} from "react-router-dom";
import {Indirizzo} from "../../models/Indirizzo";

const HomeCasaContainer = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [quotationType] = useState<QuotationType>('casa');
    const bloccaQuotazione: boolean = useSelector((state: State) => state.global.bloccaQuotazione);
    const [tipologiaAbitazione, setTipologiaAbitazione] = useState<TipologiaAbitazione>();
    const [mqCalpestabili, setMqCalpestabili] = useState<number>();
    const [tipologiaProprietario, setTipologiaProprietario] = useState<TipologiaProprietario>();
    const [indirizzo, setIndirizzo] = useState<Indirizzo>();
    const [dataNascitaProprietario, setDataNascitaProprietario] = useState<Date | null>(null);
    const listaTipologiaAbitazione = OFFERTA_ACTIVE_CASA.beniIniziali[0].fattori![0].valori;
    const listaTipologiaProprietario = OFFERTA_ACTIVE_CASA.beniIniziali[0].fattori![1].valori;
    const isReady: boolean = useSelector((state: State) => state.global.isReady);
    const localStorageVar = "params_casa"

    useEffect(() => {
        dispatch(setBackgroundCasa())
        if (props.match.path.startsWith("/externalCasa")) {
            let params = extractExternalInput(props.match.params.btoa);
            if (params.tip && params.mq && params.rel && params.ind && params.cap && params.com && params.pro && params.num && params.dn) {
                localStorage.setItem(localStorageVar, JSON.stringify({
                    tipologiaAbitazione: params.tip,
                    mqCalpestabili: params.mq,
                    tipologiaProprietario: params.rel,
                    indirizzo: {
                        strada: params.ind,
                        cap: params.cap,
                        comune: params.com,
                        provincia: params.pro,
                        numCivico: params.num
                    } as Indirizzo,
                    dataNascitaProprietario: params.dn
                }))
            history.push(HOME_CASA);
            } else {
                history.push(HOME_CASA);
            }
        }
    }, []);

    useEffect(() => {
        dispatch(loadCookie(quotationType));
    }, [dispatch]);

    useEffect(() => {
        const localStorageObj = localStorage.getItem(localStorageVar);
        let state = null;
        if (localStorageObj != null) {
            state = JSON.parse(localStorageObj)
            if (state != null && isReady) {
                let input = state;
                setTipologiaAbitazione({codice: input.tipologiaAbitazione} as TipologiaAbitazione);
                setMqCalpestabili(input.mqCalpestabili);
                setTipologiaProprietario({codice: input.tipologiaProprietario} as TipologiaProprietario);
                setIndirizzo(input.indirizzo);
                setDataNascitaProprietario(moment(input.dataNascitaProprietario, 'DD/MM/YYYY').toDate());

                localStorage.removeItem(localStorageVar);
                history.replace(HOME_CASA);

                dispatch((calcolaQuotazioneCasa({
                    tipologiaProprietario: input.tipologiaProprietario,
                    dataNascitaProprietario: moment(input.dataNascitaProprietario, 'DD/MM/YYYY').toDate(),
                    tipologiaAbitazione: input.tipologiaAbitazione,
                    mqCalpestabili: input.mqCalpestabili,
                    indirizzo: input.indirizzo
                })))
            }
        }
    }, [isReady]);

    const quota = () => {
        if (tipologiaAbitazione && mqCalpestabili && tipologiaProprietario &&
                indirizzo?.strada && indirizzo.cap && indirizzo.comune && indirizzo.provincia && indirizzo.numCivico &&
                dataNascitaProprietario) {
            dispatch((calcolaQuotazioneCasa({
                tipologiaProprietario,
                tipologiaAbitazione,
                dataNascitaProprietario,
                indirizzo,
                mqCalpestabili
            })))
        }
    }

    const changeTipologiaAbitazione = (tipologiaAbitazione: TipologiaAbitazione) => {
        setTipologiaAbitazione(tipologiaAbitazione);
    }

    const changeTipologiaProprietario = (tipologiaProprietario: TipologiaProprietario) => {
        setTipologiaProprietario(tipologiaProprietario);
    }

    const changeMqCalpestabili = (mqCalpestabili: string) => {
        let mqCalpestabiliNumber = +mqCalpestabili;
        setMqCalpestabili(mqCalpestabiliNumber);
    }

    const disableCalcolaButton = () => {
        return !(tipologiaAbitazione && mqCalpestabili && tipologiaProprietario && indirizzo && dataNascitaProprietario);
    }

    return (
            <div className="base-container container home-container">
                <Card className="q-card">
                    <Card.Body className="p-0">
                        <div className="row ms-0 me-0">
                            <div className="col-md-4 p-3 logo-container">
                                <img className="logo-company logo-tua" src={logoTua} alt=""/>
                                <div
                                        className="d-flex flex-column align-items-center justify-content-start introduction-container">
                                    <div className="introduction">
                                        Calcola la tua quotazione <br/>
                                        <span className="product">CASA</span>
                                    </div>
                                    <img className="logo-product" src={logoCasa} alt=""/>
                                </div>
                            </div>
                            <div className="col-md-8 form-container">
                                <Form className="q-form" autoComplete="off" noValidate>
                                    <FloatingLabel className="mb-3" controlId='Tipologia Abitazione'
                                                   label='Tipologia Abitazione'>
                                        <Form.Select value={tipologiaAbitazione?.codice}
                                                     disabled={bloccaQuotazione}
                                                     onChange={(v) => changeTipologiaAbitazione({codice: v.currentTarget.value} as TipologiaAbitazione)}>
                                            <option key={'empty'} value={-1}/>
                                            {listaTipologiaAbitazione?.map((o: any, index) => (
                                                    <option key={index} value={o.codice}>{o.descrizione}</option>))}
                                        </Form.Select>
                                    </FloatingLabel>

                                    <FloatingLabel className="mb-3" controlId="mqCalpestabili" label="Mq calpestabili">
                                        <Form.Control
                                                type="number"
                                                placeholder="Mq Calpestabili"
                                                value={mqCalpestabili}
                                                disabled={bloccaQuotazione}
                                                onChange={(v) => changeMqCalpestabili(v.currentTarget.value)}
                                        />
                                    </FloatingLabel>

                                    <FloatingLabel className="mb-3" controlId='Tipologia Proprietario'
                                                   label='Tipologia Proprietario'>
                                        <Form.Select value={tipologiaProprietario?.codice}
                                                     disabled={bloccaQuotazione}
                                                     onChange={(v) => changeTipologiaProprietario({codice: v.currentTarget.value} as TipologiaProprietario)}>
                                            <option key={'empty'} value={-1}/>
                                            {listaTipologiaProprietario?.map((o: any, index) => (
                                                    <option key={index} value={o.codice}>{o.descrizione}</option>))}
                                        </Form.Select>
                                    </FloatingLabel>

                                    <FloatingLabel
                                            className="mb-3"
                                            controlId="dataNascitaProprietario"
                                            label="Data di nascita proprietario"
                                    >
                                        <Form.Control
                                                required
                                                type="date"
                                                disabled={bloccaQuotazione}
                                                value={moment(dataNascitaProprietario).format("YYYY-MM-DD")}
                                                onChange={(v) => setDataNascitaProprietario(moment(v.currentTarget.value, 'YYYY/MM/DD').toDate())}
                                        />
                                    </FloatingLabel>

                                    <FloatingLabel className="mb-3" controlId="indirizzo" label="Indirizzo">
                                        <Form.Control
                                                type="text"
                                                placeholder="Indirizzo"
                                                disabled={true}
                                                value={Util.formattaIndirizzo(indirizzo)}
                                        />
                                    </FloatingLabel>

                                    <div className="mt-4 d-flex justify-content-center">
                                        <div style={{cursor: disableCalcolaButton() ? 'not-allowed' : 'pointer'}}>
                                            <Button
                                                    className="q-button q-button-primary q-home-button"
                                                    disabled={disableCalcolaButton()}
                                                    onClick={quota}
                                            >
                                                CALCOLA
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                <Copyright/>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
    );

};

export default HomeCasaContainer;
