import React, {useState} from "react";
import arrowBack from "../../../../assets/images/arrow-left.svg";
import QuotazioneHeaderProprietario from "./QuotazioneHeaderProprietario";
import QuotazioneHeaderVeicolo from "./QuotazioneHeaderVeicolo";
import QuotazioneHeaderCasa from "./QuotazioneHeaderCasa";
import QuotazioneHeaderCasaProprietario from "./QuotazioneHeaderCasaProprietario";
import QuotazioneHeaderSalute from "./QuotazioneHeaderSalute";
import logoTua from "../../../../assets/images/logoTua.png";
import QuotazioneHeaderInfortuni from "./QuotazioneHeaderInfortuni";
import {useSelector} from "react-redux";
import {State} from "../../../../redux/reducers";
import {useHistory} from "react-router-dom";

type Props = {
    quotazione: any | null;
    isSalvataggio? : boolean;
};

const QuotazioneHeader = ({quotazione, isSalvataggio = false}: Props) => {
    const history = useHistory();
    const homePage: string = useSelector((state: State) => state.global.configuration.homePage);
    const [isProdottoTuaMotor] = useState(quotazione?.prodotto?.codice === 'T007');
    const [isProdottoTuaSalute] = useState(quotazione?.prodotto?.codice === '0006');
    const [isProdottoTuaCasa] = useState(quotazione?.prodotto?.codice === '0005');
    const [isProdottoTuaInfortuni] = useState(quotazione?.prodotto?.codice === 'T0004');

    return (<div className="header-container d-flex align-items-center">
        <div className="base-container container d-flex align-items-center">
            <a href={isSalvataggio ? undefined : homePage}
               onClick={isSalvataggio ? (e) => {
                   e.preventDefault();
                   history.goBack();
               } : undefined}>
                <img className="back-icon" src={arrowBack} alt="Home"/>
            </a>
            {isProdottoTuaMotor && <QuotazioneHeaderVeicolo targa={quotazione?.targa} veicolo={quotazione?.veicolo}/>}
            {isProdottoTuaMotor && quotazione?.veicolo?.proprietario?.dataNascita && <QuotazioneHeaderProprietario proprietario={quotazione?.veicolo.proprietario}/>}
            {isProdottoTuaCasa && <QuotazioneHeaderCasa tipologiaAbitazione={quotazione.beni[0].fattori[0].valori[0].descrizione} cap={quotazione.beni[0].fattori[5].testo}
			                                            comune={quotazione.beni[0].fattori[4].testo}/>}
            {isProdottoTuaCasa && quotazione?.beni[1]?.assicurati[0] && <QuotazioneHeaderCasaProprietario dataNascita={quotazione?.beni[1].assicurati[0].dataNascita}/>}
            {isProdottoTuaSalute && <QuotazioneHeaderSalute assicurato={quotazione?.beni[0]}/>}
            {isProdottoTuaInfortuni && <QuotazioneHeaderInfortuni assicurato={quotazione?.beni[0]}/>}
            <img className="logo-company logo-tua" src={logoTua} alt=""/>
        </div>
    </div>)

}

export default QuotazioneHeader;
