import {Box, Fade, Grid} from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';
import {PropagateLoader} from 'react-spinners';
import {State} from '../redux/reducers';
import {PRIMARY} from '../styles/colors';
import {makeStyles} from '@material-ui/styles';
// @ts-ignore
import {Wave} from 'react-animated-text';

const useStyles = makeStyles({
    gridItem: {
        height: '40px',
        textAlign: 'center',
        fontFamily: 'Gilroy-Regular',
        fontWeight: 'bold',
        fontSize: '20px',
        color: PRIMARY,
    },
});

const BackGround = () => {
    const backgroundStyle = useSelector((state: State) => state.global.backgroundStyle);

    return (
        <Box className={'full-screen backGround ' + backgroundStyle}
             style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', textAlign: 'center', zIndex: -100}}>
        </Box>
    );
};

export default BackGround;
