import {UPDATE_USER} from '../actions/userActions';

export interface UserState {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const initialState = {
  uid: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
