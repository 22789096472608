import {connect, useDispatch, useSelector} from "react-redux";
import {State} from "../../redux/reducers";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Button, Card, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import QuotazioneHeaderContainer from "../Quotazione/components/QuotazioneHeader/QuotazioneHeaderContainer";
import Copyright from "../../components/Copyright";
import PrivacyContainer from "./components/PrivacyContainer";
import {salvaQuotazione, salvaQuotazioneRe} from "../../redux/actions/quotazioneActions";
import {QuotazioneReResponse, QuotazioneResponse} from "../../models/Quotazione/QuotazioneResponse";
import LocatorContainer from "./components/LocatorContainer";
import Agenzia from "../../models/Agenzia";
import AgenziaContainer from "./components/AgenziaContainer";
import Util from "../../utils/commonUtil";

const SalvataggioContainer = ({quotazione}: Props) => {
    const userState = useSelector((state:State) => state.user);
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [email, setEmail] = useState(userState.email);
    const [telefono, setTelefono] = useState("");
    const [codiceFiscale, setCodiceFiscale] = useState("");
    const [consensoProfilazione, setConsensoProfilazione] = useState(false);
    const [consensoMarketing, setConsensoMarketing] = useState<boolean | undefined>();
    const [consensoSanitario, setConsensoSanitario] = useState<boolean | undefined>();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isProdottoRe] = useState(quotazione?.prodotto?.codice !== 'T007');
    const [agenzia, setAgenzia] = useState<Agenzia | undefined>();


    const salva = () => {
        isProdottoRe ?
            dispatch(salvaQuotazioneRe({anagrafica: {nome, cognome, codiceFiscale, email, telefono, consensoProfilazione, consensoMarketing, consensoSanitario}, agenzia: agenzia?.codice}))
            :
            dispatch(salvaQuotazione({anagrafica: {nome, cognome, email, telefono, consensoProfilazione, consensoMarketing}, agenzia: agenzia?.codice}));
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!quotazione) {
            history.goBack();
        }
    }, []);

    const disableSalvataggioButton = () => {
        let boolActiveBenessere = quotazione?.prodotto?.codice !== '0006' || consensoSanitario;
        return !(nome && cognome && email && consensoProfilazione && consensoMarketing !== undefined && boolActiveBenessere && agenzia !== undefined);
    };

    return <>
        <QuotazioneHeaderContainer quotazione={quotazione} isSalvataggio={true}/>
        <div className="salvataggio-container base-container container">
            <Card className="q-card">
                <Card.Body>
                    <div className="title">Salva la tua QUOTAZIONE</div>
                    <div className="customer-info">
                        <Form className="q-form" autoComplete="off" noValidate>
                            <Container><Row>
                                <Col xs={12} sm={6}>
                                    <FloatingLabel className="mb-3" label="Nome">
                                        <Form.Control
                                            type="text"
                                            style={{textTransform: 'uppercase'}}
                                            placeholder="Nome"
                                            onChange={(v) => setNome(v.currentTarget.value)}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <FloatingLabel className="mb-3" label="Cognome">
                                        <Form.Control
                                            type="text"
                                            style={{textTransform: 'uppercase'}}
                                            placeholder="Cognome"
                                            onChange={(v) => setCognome(v.currentTarget.value)}
                                        />
                                    </FloatingLabel>
                                </Col>
                                {isProdottoRe && <Col xs={12}>
									<FloatingLabel className="mb-3" label="Codice Fiscale">
										<Form.Control
											type="text"
											style={{textTransform: 'uppercase'}}
											placeholder="Codice Fiscale"
											onChange={(v) => setCodiceFiscale(v.currentTarget.value)}
										/>
									</FloatingLabel>
								</Col>}
                                <Col xs={12} sm={6}>
                                    <FloatingLabel className="mb-3" label="Email">
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(v) => setEmail(v.currentTarget.value)}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <FloatingLabel className="mb-3" label="Cellulare">
                                        <Form.Control
                                            type="tel"
                                            placeholder="Cellulare"
                                            onChange={(v) => setTelefono(v.currentTarget.value)}
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row></Container>
                        </Form>
                    </div>
                    {agenzia !== undefined && <AgenziaContainer agenzia={agenzia} setAgenzia={setAgenzia}/>}
                    <LocatorContainer hide={agenzia !== undefined} setAgenzia={setAgenzia} indirizzo={Util.indirizzoDaQuotazione(quotazione)}/>
                    <PrivacyContainer prodotto={quotazione?.prodotto?.codice} setConsensoProfilazione={setConsensoProfilazione} setConsensoMarketing={setConsensoMarketing}
                                      setConsensoSanitario={setConsensoSanitario}/>
                    <div className="d-flex-column footer">
                        <Button className="q-button q-button-primary" disabled={disableSalvataggioButton()} onClick={salva}>RICEVI QUOTAZIONE</Button>
                        <Copyright/>
                    </div>
                </Card.Body>
            </Card>
        </div>
    </>

}

type Props = {
    quotazione: QuotazioneResponse | QuotazioneReResponse | null;
};

const mapStateToProps = (state: State) => ({
    quotazione: state.quotazione.quotazioneResult || state.quotazione.quotazioneReResult

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SalvataggioContainer);
