import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {QuotationType} from "../../models/Quotazione/QuotationType";
import {State} from "../../redux/reducers";
import {Attivita} from "../../models/Quotazione/Attivita";
import {loadCookie, setBackgroundInfortuni} from "../../redux/actions/globalActions";
import logoInfortuni from "../../assets/images/logo_infortuni.png";
import logoTua from "../../assets/images/logoTua.png";
import {Button, Card, FloatingLabel, Form} from "react-bootstrap";
import moment from "moment/moment";
import Select from "react-select";
import Copyright from "../../components/Copyright";
import Util, {extractExternalInput, findActivityByCode} from "../../utils/commonUtil";
import {Indirizzo} from "../../models/Indirizzo";
import {calcolaQuotazioneInfortuni} from "../../redux/actions/quotazioneActions";
import {HOME_INFORTUNI} from "../../navigation/CONSTANTS";
import {useHistory} from "react-router-dom";
import {updateUser} from "../../redux/actions/userActions";

const HomeInfortuniContainer = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [quotationType] = useState<QuotationType>('infortuni');
    const bloccaQuotazione: boolean = useSelector((state: State) => state.global.bloccaQuotazione);
    const [dataNascita, setDataNascita] = useState<Date | null>(null);
    const [email, setEmail] = useState<string>();
    const [attivita, setAttivita] = useState<AttivitaItem>();
    const [indirizzo, setIndirizzo] = useState<Indirizzo>();
    const [listaAttivitaDisponibili, setListaAttivitaDisponibili] = useState<AttivitaItem[]>([]);
    const listaAttivitaCompleta: Attivita[] = useSelector((state: State) => state.global.listaAttivita);
    const isReady: boolean = useSelector((state: State) => state.global.isReady);
    const localStorageVar = "params_infortuni"

    useEffect(() => {
        dispatch(setBackgroundInfortuni())
        if (props.match.path.startsWith("/externalInfortuni")) {
            let params = extractExternalInput(props.match.params.btoa);
            if (params.dn && params.ind && params.cap && params.com && params.pro && params.num && params.att) {
                localStorage.setItem(localStorageVar, JSON.stringify({
                    dataNascita: params.dn,
                    indirizzo: {
                        strada: params.ind,
                        cap: params.cap,
                        comune: params.com,
                        provincia: params.pro,
                        numCivico: params.num
                    } as Indirizzo,
                    email: params.em,
                    attivita: params.att
                }))
                history.push(HOME_INFORTUNI);
            } else {
                history.push(HOME_INFORTUNI);
            }
        }
    }, []);

    useEffect(() => {
        dispatch(loadCookie(quotationType));
    }, [dispatch]);

    const disableCalcolaButton = () => {
        return !(dataNascita && indirizzo && indirizzo?.strada && indirizzo?.cap && indirizzo?.comune && indirizzo?.provincia && indirizzo?.numCivico && attivita);
    }

    useEffect(() => {
        if (listaAttivitaCompleta.length > 0) {
            let result: any[] = [];
            listaAttivitaCompleta.forEach((attivita: any, index: number) => {
                result.push({
                    value: {id: attivita.attivita.codice, indexAttivita: index},
                    label: attivita.attivita.descrizione
                })
            })
            setListaAttivitaDisponibili(result);
        }
    }, [listaAttivitaCompleta]);

    useEffect(() => {
        const localStorageObj = localStorage.getItem(localStorageVar);
        let state = null;
        if (localStorageObj != null) {
            state = JSON.parse(localStorageObj)
            if (state != null && isReady) {
                let input = state;
                const {attivitaFinded, index} = findActivityByCode(input.attivita, listaAttivitaCompleta);
                if (index < 0) {
                    return; // Ho inserito un codice attività errato
                }
                const dataNascita = moment(input.dataNascita, 'DD/MM/YYYY').toDate()
                setDataNascita(dataNascita);
                setIndirizzo(input.indirizzo);
                setEmail(input.email);
                const attivitaObj = {
                    value: {id: attivitaFinded.codice, indexAttivita: index},
                    label: attivitaFinded.descrizione
                }
                setAttivita(attivitaObj);
                localStorage.removeItem(localStorageVar)
                history.replace(HOME_INFORTUNI);
                dispatch((calcolaQuotazioneInfortuni({
                    dataNascita,
                    indirizzo: input.indirizzo,
                    attivita: attivitaFinded
                })));
            }
        }
    }, [isReady]);

    useEffect(() => {
        dispatch(updateUser({
            email: email
        }))
    }, [email])

    const quota = () => {
        if (dataNascita &&
                indirizzo && indirizzo?.strada && indirizzo?.cap && indirizzo?.comune && indirizzo?.provincia && indirizzo?.numCivico &&
                attivita) {
            let attivitaSelezionata = listaAttivitaCompleta[attivita.value.indexAttivita];
            dispatch((calcolaQuotazioneInfortuni({dataNascita, indirizzo, attivita: attivitaSelezionata})))
        }
    }

    return (
            <div className="base-container container home-container">
                <Card className="q-card">
                    <Card.Body className="p-0">
                        <div className="row ms-0 me-0">
                            <div className="col-md-4 p-3 logo-container">
                                <img className="logo-company logo-tua" src={logoTua} alt=""/>
                                <div
                                        className="d-flex flex-column align-items-center justify-content-start introduction-container">
                                    <div className="introduction">
                                        Calcola la tua quotazione <br/>
                                        <span className="product">SOS INFORTUNI</span>
                                    </div>
                                    <img className="logo-product" src={logoInfortuni} alt=""/>
                                </div>
                            </div>
                            <div className="col-md-8 form-container">
                                <Form className="q-form" autoComplete="off" noValidate>
                                    <FloatingLabel
                                            className="mb-3"
                                            controlId="dataNascita"
                                            label="Data di nascita dell'assicurato"
                                    >
                                        <Form.Control
                                                required
                                                type="date"
                                                disabled={bloccaQuotazione}
                                                value={moment(dataNascita).format("YYYY-MM-DD")}
                                                onChange={(v) => setDataNascita(moment(v.currentTarget.value, 'YYYY/MM/DD').toDate())}
                                        />
                                    </FloatingLabel>

                                    <Select
                                            className="mb-3"
                                            classNamePrefix="select"
                                            placeholder="Seleziona un impiego"
                                            isClearable={true}
                                            isSearchable={true}
                                            noOptionsMessage={() => 'Nessuna professione trovata'}
                                            options={listaAttivitaDisponibili}
                                            value={attivita}
                                            onChange={(v) => setAttivita(v ? v : undefined)}
                                    />

                                    <FloatingLabel className="mb-3" controlId="indirizzo" label="Indirizzo">
                                        <Form.Control
                                                type="text"
                                                placeholder="Indirizzo"
                                                disabled={true}
                                                value={Util.formattaIndirizzo(indirizzo)}
                                        />
                                    </FloatingLabel>

                                    <FloatingLabel className="mb-3" controlId="email" label="Email">
                                        <Form.Control type="email" value={email} placeholder="email"
                                                      onChange={(v) => setEmail(v.currentTarget.value)}
                                                      disabled={bloccaQuotazione}/>
                                    </FloatingLabel>

                                    <div className="mt-4 d-flex justify-content-center">
                                        <div style={{cursor: disableCalcolaButton() ? 'not-allowed' : 'pointer'}}>
                                            <Button
                                                    className="q-button q-button-primary q-home-button"
                                                    disabled={disableCalcolaButton()}
                                                    onClick={quota}
                                            >
                                                CALCOLA
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                <Copyright/>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
    )
}

type AttivitaItem = {
    value: {
        id: string,
        indexAttivita: number
    },
    label: string
}

export default HomeInfortuniContainer;
