import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import INFORMATIVA_SALUTE from '../resources/informativa_salute.pdf';
import INFORMATIVA_CASA from '../resources/informativa_casa.pdf';
import INFORMATIVA_INFORTUNI from '../resources/informativa_infortuni.pdf';

const Privacy = ({children, setPrivacy}: PropsPrivacy) => {
    return <div className="privacy-form">
        <Form className="q-form" noValidate>
            <Form.Check inline label="CONSENTO" type="radio" name="privacyProfilazione" onChange={(v) => setPrivacy(true)}/>
            <Form.Check inline label="NON CONSENTO" type="radio" name="privacyProfilazione" onChange={(v) => setPrivacy(false)}/>
        </Form>
        <div>{children}</div>
    </div>
}


const PrivacyContainer = ({prodotto, setConsensoProfilazione, setConsensoMarketing, setConsensoSanitario}: Props) => {
    const [pdfInformativa, setPdfInformativa] = useState();

    useEffect(() => {
        switch (prodotto) {
            case '0006':
                setPdfInformativa(INFORMATIVA_SALUTE);
                return;
            case '0005':
                setPdfInformativa(INFORMATIVA_CASA);
                return;
            case 'T0004':
                setPdfInformativa(INFORMATIVA_INFORTUNI);
                return;
            default:
        }
    }, []);

    return <div className="privacy">
        <div>Dichiaro di aver preso visione dell'<a href={pdfInformativa} download="Informativa Privacy" target="_blank" rel="noreferrer">informativa privacy</a></div>
        {prodotto === '0006' && <Privacy setPrivacy={setConsensoSanitario}>al trattamento dei dati particolari (peso e altezza) per svolgere attività precontrattuale volta alla determinazione di quotazioni indicative e non vincolanti (quali indicate al punto 4 lett. a) dell’informativa)</Privacy>}
        <Privacy setPrivacy={setConsensoProfilazione}>al trattamento dei dati personali per finalità di marketing e commerciali effettuate dal Titolare</Privacy>
        <Privacy setPrivacy={setConsensoMarketing}>al trattamento dei dati personali per finalità di profilazione effettuata dal Titolare</Privacy>
    </div>
}

type PropsPrivacy = {
    children: string
    setPrivacy: (value: boolean) => void,
}

type Props = {
    prodotto: string | undefined,
    setConsensoProfilazione: (value: boolean) => void,
    setConsensoMarketing: (value?: boolean) => void,
    setConsensoSanitario: (value?: boolean) => void
}

export default PrivacyContainer;
