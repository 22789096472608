import moment from 'moment';
import {Indirizzo} from "../models/Indirizzo";
import {QuotazioneReResponse, QuotazioneResponse} from "../models/Quotazione/QuotazioneResponse";

class Util {
    static formattaImporto = (importo: number | undefined) => {
        if (importo == null) {
            return undefined;
        }
        try {
            if (isNaN(importo) || importo <= 0) {
                return undefined;
            }
            return (importo).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})
        } catch (e) {
            return undefined;
        }
    }

    static formattaIndirizzo = (indirizzo: Indirizzo | undefined): string => {
        if (indirizzo == null) {
            return "";
        }
        return indirizzo.strada.concat(", ").concat(indirizzo.numCivico).concat(", ").concat(indirizzo.comune).concat(", ").concat(indirizzo.provincia);
    }

    static indirizzoDaQuotazione = (quotazione: QuotazioneResponse | QuotazioneReResponse | null): string => {
        let result: Indirizzo = {strada: "", cap: "", comune: "", provincia: "", numCivico: ""};
        switch (quotazione?.prodotto.codice) {
            case 'T007': // Motor
                return "";
            case '0006': // Salute
            case 'T0004': // Infortuni
                (quotazione as QuotazioneReResponse)?.beni[0].fattori?.forEach((fattore: any) => {
                    let val = fattore.testo ? fattore.testo : '';
                    switch (fattore.codice) {
                        case "Q_PROVINCIA":
                            result.provincia = val;
                            break;
                        case "Q_COMUNE":
                            result.comune = val;
                            break;
                        case "Q_INDIRIZZO":
                            result.strada = val;
                            break;
                        case "Q_CIVICO":
                            result.numCivico = val;
                            break;
                    }
                });
                return Util.formattaIndirizzo(result);
            case '0005': // Casa
                (quotazione as QuotazioneReResponse)?.beni?.find(bene => {
                    return bene?.codiceBene === "000045"
                })?.fattori?.forEach((fattore: any) => {
                    let val = fattore.testo ? fattore.testo : '';
                    switch (fattore.codice) {
                        case "Q_PROVINCIA":
                            result.provincia = val;
                            break;
                        case "Q_COMUNE":
                            result.comune = val;
                            break;
                        case "Q_INDIRIZZO":
                            result.strada = val;
                            break;
                        case "Q_CIVICO":
                            result.numCivico = val;
                            break;
                    }
                });
                return Util.formattaIndirizzo(result);
            default:
                return "";

        }
    }

    static notNull(obj: any) {
        return obj !== null && obj !== undefined;
    }

    static isNull(obj: any) {
        return obj === null || obj === undefined;
    }

    static getTodayDate() {
        const now = new Date();
        return moment(now).format('DD/MM/YYYY');
    }

    static getYesterdayDate() {
        const now = new Date();
        return moment(now).subtract(1, 'days').format('DD/MM/YYYY');
    }

    static getCurrentYear() {
        const now = new Date();
        return now.getFullYear();
    }

    static findByKey(array: any[], key: string, value: string) {
        if (array && array.length) {
            for (let i = 0; i < array.length; i++) {
                if (array[i][key] === value) {
                    return array[i];
                }
            }
        }

        return null;
    }

    static findFattoreByCodice(array: any[], codice: string) {
        if (array && array.length) {
            for (let i = 0; i < array.length; i++) {
                if (array[i].codice === codice) {
                    return array[i];
                }
            }
        }
        return null;
    }

    static isEmpty(s: any) {
        return s == null || s === '';
    }
}

export function ensure<T>(argument: T | undefined | null, message: string = 'Valore nullo o undefined'): T {
    if (argument === undefined || argument === null) {
        throw new TypeError(message);
    }

    return argument;
}

export function isDev(): boolean {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function extractExternalInput(base64: string): any {
    let result = {};
    try {
        let params = atob(base64);
        params.split("&").forEach(param => {
            let value = param.split("=");
            (result as any)[value[0]] = value[1];
        })
    } catch (e) {
        return null;
    }
    return result;
}

export function findActivityByCode(code: string, listaAttivitaCompleta: any): any {
    let objReturn = {attivitaFinded: {}, index: -1};
    listaAttivitaCompleta.every((attivita: any, index: number) => {
        if (attivita.attivita.codice === code) {
            objReturn = {attivitaFinded: attivita, index}
            return false;
        }
        return true;
    })
    return objReturn;
}

export function findActivityGroupByCode(code: string, listaAttivitaCompleta: any): any {
    let objReturn = {gruppoFinded: {}, index: -1};
    listaAttivitaCompleta.every((attivita: any, index: number) => {
        const gruppo = attivita.gruppi.find((gruppo: any) => {
            if (gruppo.codice === code) {
                return gruppo;
            }
        })
        if (gruppo) {
            objReturn = {gruppoFinded: gruppo, index};
            return false;
        }
        return true;
    })
    return objReturn;
}


export default Util;
