import React, {useEffect, useState} from 'react';
import logoAuto from '../../assets/images/car-front-fill.svg';
import {useDispatch, useSelector} from 'react-redux';
import {loadCookie, setBackgroundAuto} from '../../redux/actions/globalActions';
import {Button, Card, FloatingLabel, Form} from 'react-bootstrap';
import {calcolaQuotazione} from '../../redux/actions/quotazioneActions';
import {QuotationType} from '../../models/Quotazione/QuotationType';
import {State} from '../../redux/reducers';
import moment from 'moment';
import Copyright from "../../components/Copyright";
import {extractExternalInput} from "../../utils/commonUtil";
import {HOME_AUTO} from "../../navigation/CONSTANTS";
import {useHistory} from "react-router-dom";
import logoTua from "../../assets/images/logoTua.png";
import {updateUser} from "../../redux/actions/userActions";

const HomeAutoContainer = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [quotationType] = useState<QuotationType>('auto');
    const bloccaQuotazione: boolean = useSelector((state: State) => state.global.bloccaQuotazione);
    const isReady: boolean = useSelector((state: State) => state.global.isReady);
    const [targa, setTarga] = useState("");
    const [email, setEmail] = useState("");
    const [dataNascitaProprietario, setDataNascitaProprietario] = useState<Date | null>(null);
    const localStorageVar = "params_auto"

    useEffect(() => {
        dispatch(setBackgroundAuto())
        if (props.match.path.startsWith("/externalAuto")) {
            let params = extractExternalInput(props.match.params.btoa);
            if (params.tar && params.dn) {
                localStorage.setItem(localStorageVar, JSON.stringify({
                    targa: params.tar,
                    dataNascitaProprietario: params.dn,
                    email: params.em
                }))
                history.push(HOME_AUTO);
            } else {
                history.push(HOME_AUTO);
            }
        }
    }, []);

    useEffect(() => {
        dispatch(loadCookie(quotationType));
    }, [dispatch]);

    useEffect(() => {
        const localStorageObj = localStorage.getItem(localStorageVar);
        let state = null;
        if (localStorageObj != null) {
            state = JSON.parse(localStorageObj)
            if (state != null && isReady) {
                let input = state;
                setTarga(input.targa);
                setDataNascitaProprietario(moment(input.dataNascitaProprietario, 'DD/MM/YYYY').toDate())
                setEmail(input.email);

                localStorage.removeItem(localStorageVar);
                history.replace(HOME_AUTO);

                dispatch(calcolaQuotazione({
                    email: input.email,
                    targa: input.targa,
                    dataNascitaProprietario: moment(input.dataNascitaProprietario, 'DD/MM/YYYY').toDate(),
                    quotationType
                }));
            }
        }
    }, [isReady]);

    useEffect(() => {
        dispatch(updateUser({
            email: email
        }))
    }, [email])

    const quota = () => {
        if (targa && dataNascitaProprietario) {
            dispatch(calcolaQuotazione({email, targa, dataNascitaProprietario, quotationType}));
        }
    };

    const disableCalcolaButton = () => {
        return !(targa && dataNascitaProprietario);
    };

    return (
        <div className="base-container container home-container">
            <Card className="q-card">
                <Card.Body className="p-0">
                    <div className="row ms-0 me-0">
                        <div className="col-md-4 p-3 logo-container">
                            <img className="logo-company logo-tua" src={logoTua} alt=""/>

                            <div
                                className="d-flex flex-column align-items-center justify-content-start introduction-container">
                                <div className="introduction">
                                    Calcola la tua quotazione <br/>
                                    <span className="product">AUTO</span>
                                </div>
                                <img className="logo-product" src={logoAuto} alt=""/>
                            </div>
                        </div>

                        <div className="col-md-8 form-container">
                            <Form className="q-form" autoComplete="off" noValidate>
                                <FloatingLabel className="mb-3" controlId="targa" label="Targa">
                                    <Form.Control
                                        type="text"
                                        style={{textTransform: 'uppercase'}}
                                        placeholder="Targa"
                                        disabled={bloccaQuotazione}
                                        value={targa}
                                        onChange={(v) => setTarga(v.currentTarget.value)}
                                    />
                                </FloatingLabel>

                                <FloatingLabel
                                    className="mb-3"
                                    controlId="dataNascitaProprietario"
                                    label="Data di nascita proprietario"
                                >
                                    <Form.Control
                                        required
                                        type="date"
                                        value={moment(dataNascitaProprietario).format("YYYY-MM-DD")}
                                        disabled={bloccaQuotazione}
                                        onChange={(v) => setDataNascitaProprietario(moment(v.currentTarget.value, 'YYYY/MM/DD').toDate())}
                                    />
                                </FloatingLabel>

                                <FloatingLabel className="mb-3" controlId="email" label="Email">
                                    <Form.Control type="email" value={email} placeholder="email"
                                                  onChange={(v) => setEmail(v.currentTarget.value)}
                                                  disabled={bloccaQuotazione}/>
                                </FloatingLabel>

                                <div className="mt-4 d-flex justify-content-center">
                                    <div style={{cursor: disableCalcolaButton() ? 'not-allowed' : 'pointer'}}>
                                        <Button
                                            className="q-button q-button-primary q-home-button"
                                            disabled={disableCalcolaButton()}
                                            onClick={quota}
                                        >
                                            CALCOLA
                                        </Button>
                                    </div>
                                </div>
                            </Form>

                            <Copyright/>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default HomeAutoContainer;
