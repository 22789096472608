import {Box, Fade, Grid} from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';
import {PropagateLoader} from 'react-spinners';
import {State} from '../redux/reducers';
import {PRIMARY} from '../styles/colors';
import {makeStyles} from '@material-ui/styles';
// @ts-ignore
import {Wave} from 'react-animated-text';

const useStyles = makeStyles({
    gridItem: {
        height: '40px',
        textAlign: 'center',
        fontFamily: 'Gilroy-Regular',
        fontWeight: 'bold',
        fontSize: '20px',
        color: PRIMARY,
    },
});

const CLoader = () => {
    const classes = useStyles();
    const pendingRequest = useSelector((state: State) => state.global.pendingRequest);

    return (
        <Fade in={pendingRequest > 0} timeout={{enter: 1500, exit: 1000}}>
            <Box className={'full-screen'}
                 style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgb(255 214 214 / 85%)', textAlign: 'center', zIndex: 1100}}>
                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} style={{height: '100%'}}>
                    <Grid item className={classes.gridItem}>
                        <PropagateLoader loading={true} size={25} color={PRIMARY}/>
                    </Grid>
                    <Grid item className={classes.gridItem}>
                        <Wave text="Caricamento in corso..." effect="stretch" effectChange={1.1}/>
                    </Grid>
                </Grid>
            </Box>
        </Fade>
    );
};

export default CLoader;
