import userIcon from "../../../../assets/images/person-circle.svg";
import moment from "moment/moment";
import React from "react";

const QuotazioneHeaderCasaProprietario = ({dataNascita}: Props) => {
    return <div className="desktop-view">
        <div className="d-flex tile">
            <img className="logo" src={userIcon} alt=""/>
            <div>
                <div>Proprietario</div>
                <div>{moment(dataNascita).format('DD/MM/YYYY')}</div>
            </div>
        </div>
    </div>
}

type Props = {
    dataNascita: number
}
export default QuotazioneHeaderCasaProprietario
