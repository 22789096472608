import {FloatingLabel, Form} from "react-bootstrap";

type Props = {
    options: any[] | undefined,
    label: string,
    selectedValue: any,
    disabled?: boolean,
    onChange: (value: string) => void,
}

const Select = ({options, label, selectedValue, disabled, onChange} : Props) => {

    return (
        <FloatingLabel className={"mb-3" + (!selectedValue ? " not-selected" : "")} controlId={label} label={label}>
            {
                options && options.length > 0 ?
			    <Form.Select disabled={disabled} onChange={(v) => onChange(v.currentTarget.value )}>
				    <option key={options![0].codice+'_void'} selected={true} style={{display: 'none'}}></option>
                    {options?.map((o: any, index) => (<option key={index} value={o.codice}>{o.descrizione}</option>))}
			    </Form.Select>
                    :
                <Form.Select disabled={true}>
                </Form.Select>
            }
        </FloatingLabel>
    )
}

export default Select;
