import React from "react";

const Copyright = () =>{
    return <p className="copyright" style={{marginTop: '2rem'}}>
        Copyright &copy; 2024 TUA Assicurazioni S.p.A. <br/>
        Codice Fiscale e Partita IVA 02816710236 R.E.A. di Milano 1716504 <br/>
        Società soggetta all’attività di direzione e coordinamento da parte della società Allianz S.p.A.

    </p>
}

export default Copyright;
