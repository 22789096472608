import {Garanzia, QuotazioneReResponse, QuotazioneResponse} from '../../models/Quotazione/QuotazioneResponse';
import Anagrafica from '../../models/Anagrafica';
import InputQuotazioneVeicolo from '../../models/Input/InputQuotazioneVeicolo';
import InputQuotazioneCasa from "../../models/Input/InputQuotazioneCasa";
import InputQuotazioneSalute from "../../models/Input/InputQuotazioneSalute";
import InputQuotazioneInfortuni from "../../models/Input/InputQuotazioneInfortuni";

export enum QuotazioneActionTypes {
    CALCOLA_QUOTAZIONE = '[QuotazioneState] CALCOLA_QUOTAZIONE',
    CALCOLA_QUOTAZIONE_CASA = '[QuotazioneState] CALCOLA_QUOTAZIONE_CASA',
    CALCOLA_QUOTAZIONE_SALUTE = '[QuotazioneState] CALCOLA_QUOTAZIONE_SALUTE',
    CALCOLA_QUOTAZIONE_INFORTUNI = '[QuotazioneState] CALCOLA_QUOTAZIONE_INFORTUNI',
    CALCOLA_QUOTAZIONE_SUCCESS = '[QuotazioneState] CALCOLA_QUOTAZIONE_SUCCESS',
    CALCOLA_QUOTAZIONE_RE_SUCCESS = '[QuotazioneState] CALCOLA_QUOTAZIONE_RE_SUCCESS',
    RICALCOLA_QUOTAZIONE = '[QuotazioneState] RICALCOLA_QUOTAZIONE',
    RICALCOLA_QUOTAZIONE_RE = '[QuotazioneState] RICALCOLA_QUOTAZIONE_RE',
    RICALCOLA_QUOTAZIONE_VEICOLO_FAIL = '[QuotazioneState] RICALCOLA_QUOTAZIONE_VEICOLO_FAIL',
    RICALCOLA_QUOTAZIONE_SUCCESS = '[QuotazioneState] RICALCOLA_QUOTAZIONE_SUCCESS',
    RICALCOLA_QUOTAZIONE_RE_SUCCESS = '[QuotazioneState] RICALCOLA_QUOTAZIONE_RE_SUCCESS',
    RICALCOLA_QUOTAZIONE_RE_FAIL = '[QuotazioneState] RICALCOLA_QUOTAZIONE_RE_FAIL',
    SALVA_QUOTAZIONE = '[QuotazioneState] SALVA_QUOTAZIONE',
    SALVA_QUOTAZIONE_SUCCESS = '[QuotazioneState] SALVA_QUOTAZIONE_SUCCESS',
    SALVA_QUOTAZIONE_RE = '[QuotazioneState] SALVA_QUOTAZIONE_RE',
    SALVA_QUOTAZIONE_RE_SUCCESS = '[QuotazioneState] SALVA_QUOTAZIONE_RE_SUCCESS',
    SALVA_QUOTAZIONE_FAILED = '[QuotazioneState] SALVA_QUOTAZIONE_FAILED',
    TOGGLE_GARANZIA = '[QuotazioneState] TOGGLE_GARANZIA',
    CAMBIO_PACCHETTO = '[QuotazioneState] CAMBIO_PACCHETTO',
    SET_VALORE_FATTORE = '[QuotazioneState] SET_VALORE_FATTORE',
}

export const salvaQuotazione = (payload: { anagrafica: Anagrafica, agenzia: string | undefined }) => {
    return {
        type: QuotazioneActionTypes.SALVA_QUOTAZIONE,
        payload,
    };
};

export const salvaQuotazioneRe = (payload: { anagrafica: Anagrafica, agenzia: string | undefined}) => {
    return {
        type: QuotazioneActionTypes.SALVA_QUOTAZIONE_RE,
        payload,
    };
};

export const salvaQuotazioneSuccess = (payload: QuotazioneResponse) => {
    return {
        type: QuotazioneActionTypes.SALVA_QUOTAZIONE_SUCCESS,
        payload,
    };
};

export const salvaQuotazioneReSuccess = (payload: QuotazioneReResponse) => {
    return {
        type: QuotazioneActionTypes.SALVA_QUOTAZIONE_RE_SUCCESS,
        payload,
    };
};

export const salvaQuotazioneFailed = () => {
    return {
        type: QuotazioneActionTypes.SALVA_QUOTAZIONE_FAILED,
    };
};

export const toggleGaranzia = (payload: { garanzia: Garanzia }) => {
    return {
        type: QuotazioneActionTypes.TOGGLE_GARANZIA,
        payload,
    };
};

export const cambioPacchetto = (payload: { pacchettoIdx: number }) => {
    return {
        type: QuotazioneActionTypes.CAMBIO_PACCHETTO,
        payload,
    };
};

export const calcolaQuotazione = (payload: InputQuotazioneVeicolo) => {
    return {
        type: QuotazioneActionTypes.CALCOLA_QUOTAZIONE,
        payload,
    };
};

export const calcolaQuotazioneCasa = (payload: InputQuotazioneCasa) => {
    return {
        type: QuotazioneActionTypes.CALCOLA_QUOTAZIONE_CASA,
        payload,
    };
};

export const calcolaQuotazioneSalute = (payload: InputQuotazioneSalute) => {
    return {
        type: QuotazioneActionTypes.CALCOLA_QUOTAZIONE_SALUTE,
        payload,
    };
};

export const calcolaQuotazioneInfortuni = (payload: InputQuotazioneInfortuni) => {
    return{
        type: QuotazioneActionTypes.CALCOLA_QUOTAZIONE_INFORTUNI,
        payload
    }
}

export const calcolaQuotazioneSuccess = (payload: { res: any; idx?: number }) => {
    return {
        type: QuotazioneActionTypes.CALCOLA_QUOTAZIONE_SUCCESS,
        payload,
    };
};

export const calcolaQuotazioneReSuccess = (payload: { res: any }) => {
    return {
        type: QuotazioneActionTypes.CALCOLA_QUOTAZIONE_RE_SUCCESS,
        payload,
    };
};

export const ricalcolaQuotazione = (payload = {}) => {
    return {
        type: QuotazioneActionTypes.RICALCOLA_QUOTAZIONE,
        payload,
    };
};

export const ricalcolaQuotazioneRe = (payload = {}) => {
    return {
        type: QuotazioneActionTypes.RICALCOLA_QUOTAZIONE_RE,
        payload,
    };
};

export const ricalcolaQuotazioneSuccess = (payload: QuotazioneResponse) => {
    return {
        type: QuotazioneActionTypes.RICALCOLA_QUOTAZIONE_SUCCESS,
        payload,
    };
};

export const ricalcolaQuotazioneReSuccess = (payload: QuotazioneReResponse) => {
    return {
        type: QuotazioneActionTypes.RICALCOLA_QUOTAZIONE_RE_SUCCESS,
        payload,
    };
};

export const ricalcolaQuotazioneReFail = () => {
    return {
        type: QuotazioneActionTypes.RICALCOLA_QUOTAZIONE_RE_FAIL,
    };
};

export const ricalcolaQuotazioneVeicoloFail = () => {
    return {
        type: QuotazioneActionTypes.RICALCOLA_QUOTAZIONE_VEICOLO_FAIL,
    };
};

