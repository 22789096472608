import {Clausola, Fattore} from "./QuotazioneResponse";
import {Attivita} from "./Attivita";
import InputQuotazioneSalute from "../Input/InputQuotazioneSalute";
import InputQuotazioneCasa from "../Input/InputQuotazioneCasa";
import InputQuotazioneInfortuni from "../Input/InputQuotazioneInfortuni";

type StatoSalute = {
    altezza: number;
    peso: number;
}

export interface BeniIniziali {
    codice: string;
    descrizione1: string;
    tipoBene: string;
    fattori?: Fattore[];
    clausole?: Clausola[];
    dataNascita?: Date;
    statoSalute?: StatoSalute;
    attivita?: Attivita;
    assicurati?: any[];
}

export function popolaLocalitaBeneIniziale(bene: BeniIniziali, input: InputQuotazioneCasa | InputQuotazioneSalute | InputQuotazioneInfortuni) {
    bene.fattori?.forEach(fattore => {
        switch (fattore.codice) {
            case "Q_PROVINCIA":
                fattore.testo = input.indirizzo.provincia;
                break;
            case "Q_COMUNE":
                fattore.testo = input.indirizzo.comune;
                break;
            case "Q_CAP":
                fattore.testo = input.indirizzo.cap;
                break;
            case "Q_INDIRIZZO":
                fattore.testo = input.indirizzo.strada;
                break;
            case "Q_CIVICO":
                fattore.testo = input.indirizzo.numCivico;
                break;
            case "Q_ISTAT":
                break;
        }
    })
}
