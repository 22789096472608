import React, {useEffect, useState} from "react";
import Agenzia from "../../../models/Agenzia";
import {Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {State} from "../../../redux/reducers";

const LocatorContainer = ({hide, setAgenzia, indirizzo}: Props) => {
    const [loading, isLoading] = useState(true);
    const url: string = useSelector((state: State) => state.global.configuration.locatorUrl);

    useEffect(() => {
        window.addEventListener('message', function (e) {
            let data = e.data;
            if (data.message === 'locatorSelection') {
                setAgenzia({
                    codice: data.codice,
                    indirizzo: data.indirizzo,
                    cap: data.cap,
                    citta: data.citta,
                    provincia: data.provincia,
                })
            }
        });
    }, []);

    const iframeLoaded = () => {
        isLoading(false)
    }

    return <>
        {!hide && <div className="locator-container">
            {loading && <div className="spinner">
				<Spinner animation="border" role="status" variant="primary"/>
				<span>Caricamento Agenzie...</span>
			</div>}
			<div className={hide || loading ? "hidden" : ""}>
				<iframe src={url + (indirizzo !== "" ? "?q=" + indirizzo : "")} title="locator-agenzie" onLoad={iframeLoaded}/>
			</div>
		</div>
        }
    </>
}

type Props = {
    hide: boolean,
    setAgenzia: (value: Agenzia) => void,
    indirizzo: string
}

export default LocatorContainer;
