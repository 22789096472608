import {QuotazioneReResponse, QuotazioneResponse} from '../../models/Quotazione/QuotazioneResponse';
import {QuotazioneActionTypes} from '../actions/quotazioneActions';
import _ from 'lodash';
import {ensure} from '../../utils/commonUtil';

export interface QuotazioneState {
    quotazioneResult: QuotazioneResponse | null;
    quotazioneReResult: QuotazioneReResponse | null;
    pacchettoIdx: number | null;
    daRicalcolare: boolean;
    salvata: boolean;
    bloccaProsegui: boolean;
}

const initialState: QuotazioneState = {
    quotazioneResult: null,
    quotazioneReResult: null,
    pacchettoIdx: null,
    daRicalcolare: false,
    salvata: false,
    bloccaProsegui: false
};

const quotazioneReducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case QuotazioneActionTypes.SET_VALORE_FATTORE:
            return handleSetValoreFattore(state, action);

        case QuotazioneActionTypes.TOGGLE_GARANZIA:
            return handleToggleGaranzia(state, action);

        case QuotazioneActionTypes.CAMBIO_PACCHETTO:
            return handleCambioPacchetto(state, action);

        case QuotazioneActionTypes.CALCOLA_QUOTAZIONE_SUCCESS:
            return handleCalcolaSuccess(state, action);

        case QuotazioneActionTypes.CALCOLA_QUOTAZIONE_RE_SUCCESS:
            return handleCalcolaReSuccess(state, action);

        case QuotazioneActionTypes.RICALCOLA_QUOTAZIONE_SUCCESS:
            return handleRicalcolaSuccess(state, action);

        case QuotazioneActionTypes.RICALCOLA_QUOTAZIONE_RE_SUCCESS:
            return handleRicalcolaReSuccess(state, action);

        case QuotazioneActionTypes.SALVA_QUOTAZIONE_SUCCESS:
            return handleSalvaSuccess(state, action);

        case QuotazioneActionTypes.SALVA_QUOTAZIONE_RE_SUCCESS:
            return handleSalvaReSuccess(state, action);

        case QuotazioneActionTypes.RICALCOLA_QUOTAZIONE_RE_FAIL:
            return handleRicalcolaReFail(state);

        case QuotazioneActionTypes.RICALCOLA_QUOTAZIONE_VEICOLO_FAIL:
            return handleRicalcolaVeicoloFail(state);

        default:
            return state;
    }
};

const handleSetValoreFattore = (state: QuotazioneState, action: { type: string; payload: any }) => {
    const idx = ensure(state.pacchettoIdx);
    const garanzia = action.payload.garanzia;
    const fattore = action.payload.fattore;
    const value = action.payload.value;

    const _q = ensure(_.cloneDeep(state.quotazioneResult));
    const _p = ensure(_q.pacchetti[idx]);
    const _g = ensure(_p.garanzie.find((g) => g.codice === garanzia.codice));
    const _f = ensure(_g.fattori.find((f) => f.codice === fattore.codice));
    _f.codiceValoreSelezionato = value.codice;

    return {
        ...state,
        daRicalcolare: true,
        quotazioneResult: _q,
    };
};

const handleToggleGaranzia = (state: QuotazioneState, action: { type: string; payload: any }) => {
    const garanzia = action.payload.garanzia;
    const q = ensure(_.cloneDeep(state.quotazioneReResult));
    const _g = ensure(q.beni[0].garanzie.find((g) => g.codice === garanzia.codice));
    _g.selezionata = garanzia.selezionata;

    return {
        ...state,
        daRicalcolare: true,
        quotazioneReResult: q,
    };
};

const handleCambioPacchetto = (state: QuotazioneState, action: { type: string; payload: any }) => {
    return {
        ...state,
        daRicalcolare: true,
        pacchettoIdx: action.payload.pacchettoIdx,
    };
};

const handleCalcolaSuccess = (state: QuotazioneState, action: { type: string; payload: any }) => {
    return {
        ...state,
        quotazioneResult: action.payload.res,
        quotazioneReResult: undefined,
        pacchettoIdx: action.payload.idx,
        salvata: false,
    };
};

const handleCalcolaReSuccess = (state: QuotazioneState, action: { type: string; payload: any }) => {
    return {
        ...state,
        quotazioneResult: undefined,
        quotazioneReResult: action.payload.res,
        salvata: false,
    };
};

const handleRicalcolaSuccess = (state: QuotazioneState, action: { type: string; payload: any }) => {
    return {
        ...state,
        quotazioneResult: action.payload,
        daRicalcolare: false,
        bloccaProsegui: false
    };
};

const handleRicalcolaVeicoloFail = (state: QuotazioneState) => {
    return{
        ...state,
        bloccaProsegui: true
    }
}


const handleRicalcolaReSuccess = (state: QuotazioneState, action: { type: string; payload: any }) => {
    return {
        ...state,
        quotazioneReResult: action.payload,
        daRicalcolare: false,
        bloccaProsegui: false
    };
};

const handleRicalcolaReFail = (state: QuotazioneState) => {
    return{
        ...state,
        bloccaProsegui: true
    }
}

const handleSalvaSuccess = (state: QuotazioneState, action: { type: string; payload: QuotazioneResponse }) => {
    const q = ensure(_.cloneDeep(state.quotazioneResult));
    q.numPreventivo = action.payload.numPreventivo;
    q.idQuottyCode = action.payload.idQuottyCode;

    return {
        ...state,
        quotazioneResult: q,
        salvata: true,
    };
};

const handleSalvaReSuccess = (state: QuotazioneState, action: { type: string; payload: QuotazioneReResponse }) => {
    const q = ensure(_.cloneDeep(action.payload));

    return {
        ...state,
        quotazioneReResult: q,
        salvata: true,
    };
};

export default quotazioneReducer;
