import {combineReducers} from 'redux';
import globalReducer, {GlobalState} from './globalReducer';
import userReducer, {UserState} from './userReducer';
import quotazioneReducer, {QuotazioneState} from './quotazioneReducer';

export interface State {
  global: GlobalState;
  user: UserState;
  quotazione: QuotazioneState;
}

export const rootReducer = combineReducers({
  global: globalReducer,
  user: userReducer,
  quotazione: quotazioneReducer,
});

export default rootReducer;
