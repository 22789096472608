export const PRIMARY = '#b92533';
export const SECONDARY = '#313131';

export const BRAND_GREEN = '#9FE57D';
export const BRAND_YELLOW = '#F5D490';
export const BRAND_LIGHT_BLUE = '#CBD5EB';

export const DARK_BLUE = '#184074';
export const BLUE = '#54A0D4';
export const PURPLE = '#7A65AA';
export const DARK_GREEN = '#4A988A';

export const SUCCESS = '#69C779';
export const INFO = '#87CEFA';
export const ORANGE_WARNING = '#FED187';
export const RED_ERROR = '#E14B5D';

export const WHITE = '#fff';
export const BLACK = '#212121';
export const GRAY_BLUE = '#3B464D';
export const DARK_GRAY = '#929292';
export const MIDDLE_GRAY = '#CCCCCC';
export const GRAY = '#EEEEEE';
export const LIGHT_GRAY = '#F2F2F2';
export const GREEN_VERA = '#00755C';
export const BLUE_BCC = '#00AFED';
