import React, {useEffect, useState} from 'react';
import {Garanzia} from "../../../models/Quotazione/QuotazioneResponse";
import Fattori from "./Fattori";
import checkBoxLock from '../../../assets/images/file-lock2-fill.svg';
import checkBoxActive from '../../../assets/images/check-square-fill.svg';
import checkBox from '../../../assets/images/square.svg';
import accordionArrowDownDisabled from '../../../assets/images/chevron-compact-down-disabled.svg';
import accordionArrowDown from '../../../assets/images/chevron-compact-down.svg';
import accordionArrowUp from '../../../assets/images/chevron-compact-up.svg';
import Util from "../../../utils/commonUtil";
import {useSelector} from "react-redux";
import {InfoHelp} from "../../../models/InfoHelp";
import {State} from "../../../redux/reducers";
import Helper from "./Helper";
import FormControl from "@material-ui/core/FormControl";
import {Form} from "react-bootstrap";

type GaranziaItemProps = {
    garanzie: Garanzia[]
    garanzia: Garanzia;
    verificaQuotazione: () => void;
    ricalcolaSottoGaranzie?: () => void;
    help: InfoHelp | undefined;
    verificaPacchettoInfortuni?: (garanzia: Garanzia, garanzie: Garanzia[]) => void;
}

const GaranziaItem = ({
                          garanzie,
                          garanzia,
                          verificaQuotazione,
                          help,
                          ricalcolaSottoGaranzie,
                          verificaPacchettoInfortuni = () => {
                          }
                      }: GaranziaItemProps) => {
    const [icon, setIcon] = useState(checkBox);
    const [accordionIcon, setAccordionIcon] = useState(accordionArrowDown);
    const [isOpen, setOpen] = useState(false);
    const [isMobile, setMobile] = useState(false)
    const [isObbligatoria] = useState(garanzia.obbligatoriaDefault);
    const haFattoriVisibili: boolean = garanzia.fattori && garanzia.fattori.length > 0 && garanzia.fattori.filter((f) => f.visibileInPreventivo && f.tipofattore)?.length > 0;
    const toggleAccordion = () => {
        if (garanzia.selezionata) {
            setOpen(!isOpen);
        }
    };

    const aggiungiRimuoviGaranzia = () => {
        if (!isObbligatoria) {
            garanzia.selezionata = !garanzia.selezionata;
            if (garanzia.selezionata) {
                setIcon(checkBoxActive);
            } else {
                setOpen(false)
                setIcon(checkBox);
            }
            verificaPacchettoInfortuni(garanzia, garanzie);
            verificaQuotazione();
        }
    };

    const modificaCodiceGaranziaSelezionato = (codice: string) => {
        garanzia.codiceGaranziaSelezionata = codice;
        if (ricalcolaSottoGaranzie) {
            ricalcolaSottoGaranzie();
        }
    }

    useEffect(() => {
        setMobile(window.innerWidth < 576)
    }, [])

    useEffect(() => {
        if (isObbligatoria) {
            setIcon(checkBoxLock);
        } else if (garanzia.selezionata) {
            setIcon(checkBoxActive);
        } else {
            setIcon(checkBox);
        }
    }, [garanzia.selezionata])

    useEffect(() => {
        if (garanzia.selezionata && isOpen) {
            setAccordionIcon(accordionArrowUp);
        } else if (garanzia.selezionata && !isOpen) {
            setAccordionIcon(accordionArrowDown);
        } else if (!garanzia.selezionata) {
            setAccordionIcon(accordionArrowDownDisabled);
        }
    }, [isOpen, garanzia.selezionata])


    window.addEventListener("resize", () => {
        setMobile(window.innerWidth < 576)
    });

    // @ts-ignore
    return (
        <div className="warranty-box">
            <div className="align-items-center warranty-container">
                <div className="row m-0 flex-fill">
                    {
                        !isMobile ?
                            <div className="col-xs-12 col-sm-12 p-0 d-flex" style={{alignItems: "center"}}>
                            <span>
                                <img className="align-self-start" alt="Check"
                                     style={{cursor: !isObbligatoria ? 'pointer' : 'not-allowed'}} src={icon}
                                     onClick={aggiungiRimuoviGaranzia}/>
                            </span>
                                {
                                    garanzia.garanzie?.length > 0 && ricalcolaSottoGaranzie ?
                                        <>
                                            <FormControl style={{marginLeft: "1.5rem"}}>
                                                <Form.Select value={garanzia.codiceGaranziaSelezionata}
                                                             onChange={(v) => modificaCodiceGaranziaSelezionato(v.currentTarget.value)}>
                                                    {
                                                        garanzia.garanzie.map((gar, index) => {
                                                            return <option key={index}
                                                                           value={gar.codice}>{gar.descrizione}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </FormControl>
                                            <Helper help={help}/>
                                        </>
                                        :
                                        <>
                                             <span className="mb-0 ms-4 q-txt-big q-txt-black q-bolder-600 pointer"
                                                   style={{
                                                       color: !garanzia.selezionata ? '#929292' : '',
                                                       cursor: garanzia.selezionata ? 'pointer' : 'default'
                                                   }}
                                                   onClick={toggleAccordion}>
                                                    {garanzia.garanzie?.length > 0 ? garanzia.garanzie.find(g => {
                                                        return g.codice === garanzia.codiceGaranziaSelezionata
                                                    })?.descrizione : garanzia.descrizione}
                                            </span>
                                            <Helper help={help}/>
                                        </>
                                }
                                <div style={{marginLeft: "auto"}} >
                                {
                                    haFattoriVisibili && <span className="float-end" >
                                        <img className="info-icon" src={accordionIcon} onClick={toggleAccordion}
                                             style={{cursor: garanzia.selezionata ? 'pointer' : 'default'}} alt="Apri"/>
                                    </span>
                                }
                                </div>
                            </div>
                            :
                            <div className="col-xs-12 col-sm-12 p-0 d-flex flex-column">
                                <div className="col-xs-12 d-flex">
                                <span>
                                    <img className="align-self-start" alt="Check"
                                         style={{cursor: !isObbligatoria ? 'pointer' : 'not-allowed'}} src={icon}
                                         onClick={aggiungiRimuoviGaranzia}/>
                                </span>
                                    {
                                        garanzia.garanzie?.length > 0 ?
                                            <FormControl style={{marginLeft: "1.5rem"}}>
                                                <Form.Select value={garanzia.codiceGaranziaSelezionata}
                                                             onChange={(v) => modificaCodiceGaranziaSelezionato(v.currentTarget.value)}>
                                                    {
                                                        garanzia.garanzie.map((gar, index) => {
                                                            return <option key={index}
                                                                           value={gar.codice}>{gar.descrizione}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </FormControl>
                                            :
                                            <span
                                                className="mb-0 ms-4 q-txt-big q-txt-black q-bolder-600 pointer flex-grow-1"
                                                style={{
                                                    color: !garanzia.selezionata ? '#929292' : '',
                                                    cursor: garanzia.selezionata ? 'pointer' : 'default'
                                                }}
                                                onClick={toggleAccordion}>
                                    {garanzia.garanzie?.length > 0 ? garanzia.garanzie.find(g => {
                                        return g.codice === garanzia.codiceGaranziaSelezionata
                                    })?.descrizione : garanzia.descrizione}
                                    </span>
                                    }
                                    <Helper help={help}/>
                                </div>
                                <div className="col-xs-12 d-flex justify-content-center">
                                    {haFattoriVisibili && <span className="float-end">
                            <img className="info-icon" src={accordionIcon} onClick={toggleAccordion}
                                 style={{cursor: garanzia.selezionata ? 'pointer' : 'default'}} alt="Apri"/>
                        </span>}
                                </div>
                            </div>
                    }
                </div>
                {haFattoriVisibili && isOpen && (
                    <div className="factors-box">
                        <Fattori fattori={garanzia.fattori} verificaQuotazione={verificaQuotazione}/>
                    </div>
                )}
            </div>
        </div>
    )
}

type Props = {
    garanzie: Garanzia[] | undefined;
    verificaQuotazione: () => void;
    verificaPacchettoInfortuni?: (garanzia: Garanzia, garanzie: Garanzia[]) => void
    ricalcolaSottoGaranzie?: () => void;
};

const Garanzie = ({garanzie, verificaQuotazione, verificaPacchettoInfortuni, ricalcolaSottoGaranzie}: Props) => {
    const helpers: Map<string, InfoHelp> = useSelector((state: State) => state.global.infoHelpMap);
    return <>{garanzie?.map((g: Garanzia) =>
        (
            <GaranziaItem garanzie={garanzie} garanzia={g} verificaQuotazione={verificaQuotazione} key={g.codice}
                          help={helpers.get(g.codice)} verificaPacchettoInfortuni={verificaPacchettoInfortuni}
                          ricalcolaSottoGaranzie={ricalcolaSottoGaranzie}/>
        ))
    }</>
}

export default Garanzie;
