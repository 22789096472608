import React, {useEffect, useState} from "react";
import {loadCookie, setBackgroundSalute} from "../../redux/actions/globalActions";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {QuotationType} from "../../models/Quotazione/QuotationType";
import {Button, Card, FloatingLabel, Form} from "react-bootstrap";
import logoSalute from "../../assets/images/heart-pulse.svg";
import moment from "moment";
import Copyright from "../../components/Copyright";
import {State} from "../../redux/reducers";
import Select from "react-select";
import {calcolaQuotazioneSalute} from "../../redux/actions/quotazioneActions";
import {Attivita} from "../../models/Quotazione/Attivita";
import Util, {extractExternalInput, findActivityGroupByCode} from "../../utils/commonUtil";
import {HOME_SALUTE} from "../../navigation/CONSTANTS";
import logoTua from "../../assets/images/logoTua.png";
import {Indirizzo} from "../../models/Indirizzo";
import {updateUser} from "../../redux/actions/userActions";

const HomeSaluteContainer = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [quotationType] = useState<QuotationType>('salute');
    const bloccaQuotazione: boolean = useSelector((state: State) => state.global.bloccaQuotazione);
    const [dataNascita, setDataNascita] = useState<Date | null>(null);
    const [peso, setPeso] = useState<number>();
    const [altezza, setAltezza] = useState<number>();
    const [email, setEmail] = useState<string>();
    const [indirizzo, setIndirizzo] = useState<Indirizzo>();
    const listaAttivitaCompleta: Attivita[] = useSelector((state: State) => state.global.listaAttivita);
    const [listaAttivitaDisponibili, setListaAttivitaDisponibili] = useState<AttivitaItem[]>([]);
    const [attivita, setAttivita] = useState<Attivita>();
    const [attivitaItem, setAttivitaItem] = useState<AttivitaItem>();
    const isReady: boolean = useSelector((state: State) => state.global.isReady);
    const localStorageVar = "params_salute"

    useEffect(() => {
        dispatch(setBackgroundSalute())
        if (props.match.path.startsWith("/externalSalute")) {
            let params = extractExternalInput(props.match.params.btoa);
            if (params.pes && params.alt && params.dn && params.ind && params.cap && params.com && params.pro && params.num && params.att) {
                localStorage.setItem(localStorageVar, JSON.stringify({
                    peso: params.pes,
                    altezza: params.alt,
                    dataNascita: params.dn,
                    indirizzo: {strada: params.ind, cap: params.cap, comune: params.com, provincia: params.pro, numCivico: params.num} as Indirizzo,
                    email: params.em,
                    attivita: params.att
                }))
            history.push(HOME_SALUTE);
            } else {
                history.push(HOME_SALUTE);
            }
        }
    }, []);

    useEffect(() => {
        dispatch(loadCookie(quotationType));
    }, [dispatch]);

    useEffect(() => {
        if (listaAttivitaCompleta.length > 0) {
            let result: any[] = [];
            listaAttivitaCompleta.forEach((attivita: any, index: number) => {
                attivita.gruppi.forEach((gruppo: any) => {
                    result.push({
                        value: {id: gruppo.codice, indexAttivita: index},
                        label: gruppo.descrizione
                    })
                })
            })
            setListaAttivitaDisponibili(result);
        }
    }, [listaAttivitaCompleta]);

    useEffect(() => {
        const localStorageObj = localStorage.getItem(localStorageVar);
        let state = null;
        if (localStorageObj != null) {
            state = JSON.parse(localStorageObj)
            if (state != null && isReady) {
                const {peso, altezza, indirizzo, email, attivita, dataNascita} = state;
                const {gruppoFinded, index} = findActivityGroupByCode(attivita, listaAttivitaCompleta);
                if (index < 0) {
                    return; // Ho inserito un codice attività errato
                }
                const dataNascitaParsed = moment(dataNascita, 'DD/MM/YYYY').toDate()
                setPeso(peso);
                setAltezza(altezza);
                setDataNascita(dataNascitaParsed);
                setIndirizzo(indirizzo);
                setEmail(email)
                const attivitaObj = {
                    value: {id: gruppoFinded.codice, indexAttivita: index},
                    label: gruppoFinded.descrizione
                }
                let attivitaSelezionata = listaAttivitaCompleta[index] as Attivita;
                attivitaSelezionata.tipo = {codice: gruppoFinded.codice, descrizione: gruppoFinded.descrizione}
                onAttivitaSelezionata(attivitaObj);
                history.replace(HOME_SALUTE);
                localStorage.removeItem(localStorageVar);
                dispatch(calcolaQuotazioneSalute({
                    dataNascita: dataNascitaParsed,
                    peso,
                    altezza,
                    indirizzo,
                    attivita: attivitaSelezionata,
                    email
                }));
            }
        }
    }, [isReady]);


    const changeAltezza = (altezza: string) => {
        let altezzaNumber = +altezza;
        setAltezza(altezzaNumber);
    }

    const changePeso = (peso: string) => {
        let pesoNumber = +peso;
        setPeso(pesoNumber);
    }

    const disableCalcolaButton = () => {
        return !(peso && altezza &&
                indirizzo?.strada && indirizzo?.comune && indirizzo?.provincia && indirizzo?.cap && indirizzo?.numCivico &&
                dataNascita && attivita);
    }

    const quota = () => {
        if (peso && altezza &&
                indirizzo && indirizzo?.comune && indirizzo?.provincia && indirizzo?.cap && indirizzo?.numCivico &&
                dataNascita && attivita) {
            dispatch(calcolaQuotazioneSalute({dataNascita, peso, altezza, indirizzo, attivita, email}));
        }
    };

    const onAttivitaSelezionata = (attivita: any) => {
        if (attivita != null) {
            let attivitaSelezionata = listaAttivitaCompleta[attivita.value.indexAttivita];
            attivitaSelezionata.tipo = {codice: attivita.value.id, descrizione: attivita.label}
            setAttivita(attivitaSelezionata);
            setAttivitaItem(attivita);
        } else {
            setAttivita(undefined);
            setAttivitaItem(undefined);
        }
    }

    useEffect(() => {
        dispatch(updateUser({
            email: email
        }))
    }, [email])

    return (
            <div className="base-container container home-container">
                <Card className="q-card">
                    <Card.Body className="p-0">
                        <div className="row ms-0 me-0">
                            <div className="col-md-4 p-3 logo-container">
                                <img className="logo-company logo-tua" src={logoTua} alt=""/>
                                <div className="d-flex flex-column align-items-center justify-content-start introduction-container">
                                    <div className="introduction">
                                        Calcola la tua quotazione <br/>
                                        <span className="product">SALUTE</span>
                                    </div>
                                    <img className="logo-product" src={logoSalute} alt=""/>
                                </div>
                            </div>
                            <div className="col-md-8 form-container">
                                <Form className="q-form" autoComplete="off" noValidate>
                                    <FloatingLabel className="mb-3" controlId="dataNascita" label="Data di nascita dell'assicurato">
                                        <Form.Control
                                                required
                                                type="date"
                                                disabled={bloccaQuotazione}
                                                value={moment(dataNascita).format("YYYY-MM-DD")}
                                                onChange={(v) => setDataNascita(moment(v.currentTarget.value, 'YYYY/MM/DD').toDate())}
                                        />
                                    </FloatingLabel>

                                    <FloatingLabel className="mb-3" controlId="peso" label="Peso">
                                        <Form.Control
                                                required
                                                min={0}
                                                type="number"
                                                placeholder="Peso"
                                                value={peso}
                                                disabled={bloccaQuotazione}
                                                onChange={(v) => changePeso(v.currentTarget.value)}
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-3" controlId="altezza" label="Altezza">
                                        <Form.Control
                                                required
                                                min={0}
                                                type="number"
                                                placeholder="Altezza"
                                                value={altezza}
                                                disabled={bloccaQuotazione}
                                                onChange={(v) => changeAltezza(v.currentTarget.value)}
                                        />
                                    </FloatingLabel>

                                    <Select
                                            className="mb-3"
                                            classNamePrefix="select"
                                            placeholder="Seleziona un impiego"
                                            isClearable={true}
                                            isSearchable={true}
                                            noOptionsMessage={() => 'Nessuna professione trovata'}
                                            options={listaAttivitaDisponibili}
                                            value={attivitaItem}
                                            onChange={(v) => onAttivitaSelezionata(v)}
                                    />

                                    <FloatingLabel className="mb-3" controlId="indirizzo" label="Indirizzo">
                                        <Form.Control
                                                type="text"
                                                placeholder="Indirizzo"
                                                disabled={true}
                                                value={Util.formattaIndirizzo(indirizzo)}
                                        />
                                    </FloatingLabel>

                                    <FloatingLabel className="mb-3" controlId="email" label="Email">
                                        <Form.Control type="email" value={email} placeholder="email" onChange={(v) => setEmail(v.currentTarget.value)} disabled={bloccaQuotazione}/>
                                    </FloatingLabel>

                                    <div className="mt-4 d-flex justify-content-center">
                                        <div style={{cursor: disableCalcolaButton() ? 'not-allowed' : 'pointer'}}>
                                            <Button
                                                    className="q-button q-button-primary q-home-button"
                                                    disabled={disableCalcolaButton()}
                                                    onClick={quota}
                                            >
                                                CALCOLA
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                <Copyright/>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
    )
}

type AttivitaItem = {
    value: {
        id: string,
        indexAttivita: number
    },
    label: string
}

export default HomeSaluteContainer;
