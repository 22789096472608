import React, {useState} from "react";
import iconInfortuni from "../../../../assets/images/logo_infortuni.png";
import {BeniIniziali} from "../../../../models/Quotazione/BeniIniziali";
import {Button, Modal} from "react-bootstrap";
import moment from "moment";
import {Fattore} from "../../../../models/Quotazione/QuotazioneResponse";
import QuotazioneHeaderInfortuniDettaglio from "./QuotazioneHeaderInfortuniDettaglio";

const QuotazioneHeaderInfortuni = ({assicurato}: Props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const componiIndirizzo = (fattori?: Fattore[]): string => {
        let indirizzo = '', numCivico = '', citta = '', provincia = '';
        fattori?.forEach(fattore => {
            let val = fattore.testo ? fattore.testo : '';
            switch (fattore.codice) {
                case "Q_PROVINCIA":
                    provincia = val;
                    break;
                case "Q_COMUNE":
                    citta =val;
                    break;
                case "Q_INDIRIZZO":
                    indirizzo = val;
                    break;
                case "Q_CIVICO":
                    numCivico = val;
                    break;
            }
        })
        return indirizzo.concat(", ").concat(numCivico).concat(", ").concat(citta).concat(" (").concat(provincia).concat(")");
    }

    return (<>
            <Modal show={show} onHide={handleClose} size="lg" dialogClassName="modal-veicolo">
                <Modal.Body>
                    <QuotazioneHeaderInfortuniDettaglio dataNascita={assicurato.assicurati?.[0].dataNascita}
                                                     attivita={assicurato.attivita?.tipo?.descrizione} indirizzo={componiIndirizzo(assicurato.fattori)}/>
                    <Button variant="primary" onClick={handleClose}>CHIUDI</Button>
                </Modal.Body>
            </Modal>
            <div className="no-mobile-view">
                <div className="d-flex tile">
                    <img className="logo" src={iconInfortuni}/>
                    <div className="insured border border-light rounded" onClick={handleShow}>
                        <div>ASSICURATO</div>
                        <div>{moment(assicurato.assicurati?.[0].dataNascita).format('DD/MM/YYYY')}</div>
                    </div>
                </div>
            </div>
            <div className="mobile-view">
                <div className="d-flex tile">
                    <img className="logo pointer" src={iconInfortuni} onClick={handleShow} alt="Info"/>
                </div>
            </div>
        </>
    )
}

type Props = { assicurato: BeniIniziali; }

export default QuotazioneHeaderInfortuni;