import {Veicolo} from "../../../../models/Quotazione/QuotazioneResponse";
import React, {useEffect, useState} from "react";
import iconAuto from "../../../../assets/images/car-front-fill.svg";
import iconMoto from "../../../../assets/images/motorbike-front-fill.svg";
import {Button, Modal} from "react-bootstrap";
import QuotazioneHeaderVeicoloDettaglio from "./QuotazioneHeaderVeicoloDettaglio";

const QuotazioneHeaderVeicolo = ({targa, veicolo}: Props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [icon, setIcon] = useState("")

    useEffect(() => {
        switch (veicolo.tipoVeicolo){
            case "A":
                setIcon(iconAuto)
                break;
            case "M":
            case "W":
                setIcon(iconMoto)
                break;
        }
    }, [])
    return (<>
            <Modal show={show} onHide={handleClose} size="lg" dialogClassName="modal-veicolo" >
                <Modal.Body>
                    <QuotazioneHeaderVeicoloDettaglio targa={targa} veicolo={veicolo} icon={icon}/>
                    <Button variant="primary" onClick={handleClose}>CHIUDI</Button>
                </Modal.Body>
            </Modal>
            <div className="no-mobile-view">
                <div className="d-flex tile">
                    <img className={`logo ${veicolo.tipoVeicolo === 'A' ? '' : 'logo-bike'}`} src={icon} alt="" />
                    <div className="insured border border-light rounded" onClick={handleShow}>
                        <div>{targa}</div>
                        <div>{veicolo.marca} - {veicolo.modello}</div>
                    </div>
                </div>
            </div>
            <div className="mobile-view">
                <div className="d-flex tile">
                    <img className={`logo pointer ${veicolo.tipoVeicolo === 'A' ? '' : 'logo-bike'}`} src={icon} onClick={handleShow} alt="Info"/>
                </div>
            </div>
        </>
    )
}

type Props =
    {
        targa: string;
        veicolo: Veicolo;
    }
export default QuotazioneHeaderVeicolo
