import {takeEvery} from 'redux-saga/effects';
import {QuotazioneActionTypes} from '../actions/quotazioneActions';
import {
  calcolaQuotazioneAsync,
  calcolaQuotazioneCasaAsync, calcolaQuotazioneInfortuniAsync,
  calcolaQuotazioneSaluteAsync,
  ricalcolaQuotazioneAsync,
  ricalcolaQuotazioneReAsync,
  salvaQuotazioneAsync,
  salvaQuotazioneReAsync
} from './home/calcolaQuotazioneSaga';
import {GlobalActionTypes} from '../actions/globalActions';
import {loadAllestimentiDRAsync, loadAllestimentiQRAsync, loadMarcheDRAsync, loadMarcheQRAsync, loadModelliDRAsync, loadModelliQRAsync,} from './global/loadMarcheModelliAllestimentiSaga';
import {loadConfigAsync, loadCookieAsync, loadElencoAttivitaAsync, loadInfoHelpAsync, loadInfoHelpReAsync, loadOffertaAsync} from './global/loadCookieSaga';

export default function* rootSaga() {
  yield takeEvery(QuotazioneActionTypes.CALCOLA_QUOTAZIONE, calcolaQuotazioneAsync);
  yield takeEvery(QuotazioneActionTypes.CALCOLA_QUOTAZIONE_CASA, calcolaQuotazioneCasaAsync);
  yield takeEvery(QuotazioneActionTypes.CALCOLA_QUOTAZIONE_SALUTE, calcolaQuotazioneSaluteAsync);
  yield takeEvery(QuotazioneActionTypes.CALCOLA_QUOTAZIONE_INFORTUNI, calcolaQuotazioneInfortuniAsync);
  yield takeEvery(QuotazioneActionTypes.RICALCOLA_QUOTAZIONE, ricalcolaQuotazioneAsync);
  yield takeEvery(QuotazioneActionTypes.RICALCOLA_QUOTAZIONE_RE, ricalcolaQuotazioneReAsync);
  yield takeEvery(QuotazioneActionTypes.SALVA_QUOTAZIONE, salvaQuotazioneAsync);
  yield takeEvery(QuotazioneActionTypes.SALVA_QUOTAZIONE_RE, salvaQuotazioneReAsync);
  yield takeEvery(QuotazioneActionTypes.CAMBIO_PACCHETTO, ricalcolaQuotazioneAsync);

  yield takeEvery(GlobalActionTypes.LOAD_COOKIE, loadCookieAsync);
  yield takeEvery(GlobalActionTypes.LOAD_OFFERTA_AUTO, loadOffertaAsync);
  yield takeEvery(GlobalActionTypes.LOAD_INFO_HELP, loadInfoHelpAsync);
  yield takeEvery(GlobalActionTypes.LOAD_INFO_HELP_RE, loadInfoHelpReAsync);

  yield takeEvery(GlobalActionTypes.LOAD_MARCHE_QR, loadMarcheQRAsync);
  yield takeEvery(GlobalActionTypes.LOAD_MODELLI_QR, loadModelliQRAsync);
  yield takeEvery(GlobalActionTypes.LOAD_ALLESTIMENTI_QR, loadAllestimentiQRAsync);

  yield takeEvery(GlobalActionTypes.LOAD_MARCHE_DR, loadMarcheDRAsync);
  yield takeEvery(GlobalActionTypes.LOAD_MODELLI_DR, loadModelliDRAsync);
  yield takeEvery(GlobalActionTypes.LOAD_ALLESTIMENTI_DR, loadAllestimentiDRAsync);

  yield takeEvery(GlobalActionTypes.LOAD_ELENCO_ATTIVITA, loadElencoAttivitaAsync);

  yield takeEvery(GlobalActionTypes.LOAD_CONFIG, loadConfigAsync);
}
